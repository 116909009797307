import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IRoyaltyOverallThunkProps {
  user_id?: string | string[];
  context?: GetServerSidePropsContext;
}

export const getRoyaltyOverallThunk = createAsyncThunk(
  'statistics/getRoyaltyOverall',
  async ({ user_id, context }: IRoyaltyOverallThunkProps) => {
    const apiPointName = 'getRoyaltyOverall';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: { user_id },
      context,
    });
  },
);
