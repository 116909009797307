import { GetServerSidePropsContext, NextPageContext } from 'next';

import { logFetchError, logFetchInfo } from './logFetchData';

export const fetchWrapper = (
  fetchUrl: string,
  options: RequestInit,
  context?: GetServerSidePropsContext | NextPageContext,
): Promise<Response> => {
  return fetch(fetchUrl, options)
    .then(response => {
      logFetchInfo(
        response,
        options.method,
        context
          ? (context as GetServerSidePropsContext).resolvedUrl ||
              (context as NextPageContext).asPath
          : '',
      );
      return response;
    })
    .catch(error => {
      logFetchError(
        error,
        context
          ? (context as GetServerSidePropsContext).resolvedUrl ||
              (context as NextPageContext).asPath
          : '',
      );
      return error;
    });
};
