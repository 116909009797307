import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getEncryptKeyThunk } from 'sp-redux/thunks/payments/getEncryptKeyThunk';
import { getMandarinRegisterUserThunk } from 'sp-redux/thunks/payments/getMandarinRegisterUserThunk';

import { IPaymentsState } from './types';

const initialState: IPaymentsState = {
  encryptKey: {
    public_encryption_key: '',
    errors: [],
  },
  mandarinRegisterUser: {
    errors: [],
  },
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getEncryptKeyThunk.fulfilled, (state, action) => {
      state.encryptKey = action.payload.result;
    });
    builder.addCase(getMandarinRegisterUserThunk.fulfilled, (state, action) => {
      state.mandarinRegisterUser.errors = action.payload.errors;
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IPaymentsState>) => {
        return action.payload.payments;
      },
    );
  },
});

export const paymentsReducer = paymentsSlice.reducer;
