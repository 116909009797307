import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetAudioBookEmployeesByAudioIdSignature {
  bookId: string;
  context?: GetServerSidePropsContext;
}

export interface IGetAudioBookEmployeesByAudioIdRequest {
  id: string;
}

export const getAudioBookEmployeesByAudioIdThunk = createAsyncThunk(
  'audioBooks/getAudioBookEmployees',
  async ({ bookId, context }: IGetAudioBookEmployeesByAudioIdSignature) => {
    const apiPointName = 'getAudioBookEmployees';
    if (bookId) {
      return await doFetch<typeof apiPointName>({
        apiPointName,
        context,
        urlParams: { bookId },
      });
    }
  },
);
