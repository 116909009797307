import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux/index';
import type { ICommentTemplatesType } from 'sp-redux/slices/commentTemplates/types';
import { getCommentTemplatesThunk } from 'sp-redux/thunks/commentTemplates/getCommentTemplatesThunk';

const initialState: ICommentTemplatesType[] = [];

const CommentTemplatesSlice = createSlice({
  name: 'commentTemplate',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(getCommentTemplatesThunk.fulfilled, (state, action) => {
      return action.payload.result.length ? action.payload.result : state;
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<ICommentTemplatesType[]>) => {
        return action.payload.commentTemplates;
      },
    );
  },
});

export const commentTemplatesReducer = CommentTemplatesSlice.reducer;
