import { isTestEnvironment } from 'utils/environment';

export const firebaseConfigTest = {
  apiKey: 'AIzaSyBgPFnv4VIqMHpACi4tMW2CscRFFcdeKHw',
  authDomain: 'selfpub.firebaseapp.com',
  projectId: 'selfpub',
  storageBucket: 'selfpub.appspot.com',
  messagingSenderId: '1085068974084',
  appId: '1:1085068974084:web:f14701590ab0af204ffd51',
  measurementId: 'G-GEN3RP3861',
};

export const firebaseConfigProd = {
  apiKey: 'AIzaSyCxj4Lf1h_tE4OhJ5SOXVWZIkAtbPS4III',
  authDomain: 'selfpub-test-184ae.firebaseapp.com',
  projectId: 'selfpub-test-184ae',
  storageBucket: 'selfpub-test-184ae.appspot.com',
  messagingSenderId: '880045266459',
  appId: '1:880045266459:web:b1b609370d64b881784abe',
  measurementId: 'G-D9QVK2SLE5',
};

export const ID_YANDEX_METRIKA_PROD = '45878149';
export const ID_YANDEX_METRIKA_TEST = '93384087';

export const ID_YANDEX_METRIKA = isTestEnvironment()
  ? ID_YANDEX_METRIKA_TEST
  : ID_YANDEX_METRIKA_PROD;

export const YANDEX_METRIKA_AB_COOKIE_NAME = '_ymab_param';

export const YANDEX_USERSPLIT_URL = 'https://uaas.yandex.ru/v1/exps';

export const UX_FEEDBACK_ID = 'coq6mzh3p0cas892ohs9c961';
