import type { TIllustrationTypes } from 'components/basic/Illustration/types';

export const OAUTH_LITRES_CLIENT_ID = '1097876726';

export const OAUTH_MODALS_TEXTS = {
  connection: {
    title: 'Подключить аккаунт Литрес',
    info: 'При ответе на отзыв или комментарий вы сможете выбрать, от имени какой из ваших персон будет опубликован авторский ответ.',
    buttonText: 'Подключить',
  },
  success: {
    title: 'Аккаунт успешно подключен',
    info: 'Общайтесь со своими читателями и отвечайте на их отзывы. Ответ будет опубликован на Литрес и выделен как авторский.',
    buttonText: 'Перейти к отзывам',
  },
};

export const OAUTH_RESULT = {
  success: {
    picture: 'successUnion' as TIllustrationTypes,
    text: 'Ура, теперь можно закрыть страницы и вернуться на нужную страницу!',
  },
  error: {
    picture: 'errorUnion' as TIllustrationTypes,
    text: 'Произошла ошибка, попробуйте еще раз.',
  },
};

export const OAUTH_LOADING = {
  text: 'Не закрывайте страницу, пока идет авторизация',
};
