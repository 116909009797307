import { createAsyncThunk } from '@reduxjs/toolkit';

import { doFetch } from 'utils/doFetch';

export const removeBookThunk = createAsyncThunk(
  'myBooks/removeBook',
  async (bookId: number) => {
    const apiPointName = 'removeBook';

    await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: { bookId },
    });

    return { bookId };
  },
);
