import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getProfileVerificationThunk = createAsyncThunk(
  'profile/getVerificationProfile',
  async ({
    id,
    context,
  }: {
    id: string;
    context?: GetServerSidePropsContext;
  }) => {
    const apiPointName = 'getVerificationProfile';

    const data = await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { id },
    });
    return { id, ...data };
  },
);
