import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getAudioBookCoverThunk = createAsyncThunk(
  'bookMeta/getAudioBookCover',
  async ({
    audioId,
    context,
  }: {
    audioId: string;
    context?: GetServerSidePropsContext;
  }) => {
    const apiPointName = 'getAudioBookCover';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { audioId },
    });
  },
);
