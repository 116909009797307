import { getMetrika } from 'analytics/headScriptAnalytics';
import Head from 'next/head';
import type { FC } from 'react';

export const YandexMetrikaScripts: FC = () => {
  const MetrikaScripts = getMetrika();

  return (
    <Head>
      {MetrikaScripts.script}
      {MetrikaScripts.noscript}
    </Head>
  );
};
