import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetRoyaltyStatisticsThunkProps {
  date_from: number;
  date_to: number;
  user_id?: string | string[];
  book_ids?: string | string[];
  context?: GetServerSidePropsContext;
}
export const getAudioRoyaltySumsThunk = createAsyncThunk(
  'statistics/getAudioRoyaltySums',
  async ({
    date_from,
    date_to,
    user_id,
    book_ids,
    context,
  }: IGetRoyaltyStatisticsThunkProps) => {
    const apiPointName = 'getAudioRoyaltySums';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: {
        date_from,
        date_to,
        user_id,
        book_ids,
      },
      context,
    });
  },
);
