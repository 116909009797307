import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IPublisherTotalStatisticsThunkProps {
  date_from: string;
  date_to: string;
  art_type?: 'text' | 'audio';
  context?: GetServerSidePropsContext;
}

export const getPublisherTotalStatisticsThunk = createAsyncThunk(
  'statistics/getPublisherTotalStatistics',
  async ({
    date_from,
    date_to,
    art_type,
    context,
  }: IPublisherTotalStatisticsThunkProps) => {
    const apiPointName = 'getPublisherTotalStatistics';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: { date_from, date_to, art_type },
      context,
    });
  },
);
