import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getBookPriceThunk = createAsyncThunk(
  'bookPrice/getBookPrice',
  async (context: GetServerSidePropsContext) => {
    const apiPointName = 'getBookPrices';
    return await doFetch<typeof apiPointName>({ apiPointName, context });
  },
);
