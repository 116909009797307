import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext, NextPageContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface ICirculationPdfGenerationThunkProps {
  context?: GetServerSidePropsContext | NextPageContext;
  bookId: number;
}

export const getCirculationPdfGenerationThunk = createAsyncThunk(
  'circulation/pdf/generation',
  async ({ context, bookId }: ICirculationPdfGenerationThunkProps) => {
    const apiPointName = 'getCirculationPdfGeneration';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: {
        bookId,
      },
    });
  },
);
