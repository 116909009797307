import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import type { TAppState } from 'sp-redux';

import { doFetch } from 'utils/doFetch';

export const getDocumentThunk = createAsyncThunk(
  'document/getDocument',
  async (context: GetServerSidePropsContext, thunkAPI) => {
    const apiPointName = 'getDocument';
    const state = thunkAPI.getState() as TAppState;

    if (!state.userInfo.isLogged) {
      return;
    }

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: {
        profileId: state.userInfo.profile.id || 0,
      },
      context,
    });
  },
);
