import type { IFilterGroupName } from 'sp-redux/slices/moderator/types';

export enum EResultType {
  BOOK = 'book',
  PROFILE = 'profile',
}

export const filtersGroup: Record<string, IFilterGroupName> = {
  status_group: {
    send_agent: {
      name: 'Отправлена агенту',
      checked: false,
    },
    approved_moderator: {
      name: 'Одобрена модератором',
      checked: false,
    },
    verified_agent: {
      name: 'Проверяется агентом',
      checked: false,
    },
  },
  processing_time: {
    twenty_four: {
      name: 'successTimer',
      checked: false,
    },
    sixteen: {
      name: 'warningTimer',
      checked: false,
    },
    eight: {
      name: 'dangerTimer',
      checked: false,
    },
    time_is_up: {
      name: 'alertTimer',
      checked: false,
    },
  },
  my_checks_group: {
    my_checks: {
      name: 'Я:',
      checked: false,
    },
  },
  license_type: {
    exclusive: {
      name: 'Эксклюзивная',
      checked: false,
    },
    non_exclusive: {
      name: 'Базовая',
      checked: false,
    },
    free: {
      name: 'Бесплатная',
      checked: false,
    },
    draft: {
      name: 'Черновик',
      checked: false,
    },
  },
  author_status_group: {
    not_filled: {
      name: 'Не заполнен',
      checked: false,
    },
    not_confirmed: {
      name: 'Не подтвержден',
      checked: false,
    },
    confirmed: {
      name: 'Подтвержден',
      checked: false,
    },
  },
  who_returned_group: {
    author: {
      name: 'Автор',
      checked: false,
    },
    main_moderator: {
      name: 'Главный модератор',
      checked: false,
    },
  },
};
