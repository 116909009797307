import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetOrderInfoByOrderId {
  orderId: string;
  context?: GetServerSidePropsContext;
}

export const getOrderInfoByOrderId = createAsyncThunk(
  'services/getOrderInfoByOrderId',
  async ({ orderId, context }: IGetOrderInfoByOrderId) => {
    const apiPointName = 'getOrderInfoByOrderId';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { orderId },
    });
  },
);
