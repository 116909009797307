import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IPublisherGeneralStatisticsThunkProps {
  date_from: string;
  date_to: string;
  art_type: 'text' | 'audio';
  context?: GetServerSidePropsContext;
}

export const getPublisherGeneralStatisticsThunk = createAsyncThunk(
  'statistics/getPublisherGeneralStatistics',
  async ({
    date_from,
    date_to,
    art_type,
    context,
  }: IPublisherGeneralStatisticsThunkProps) => {
    const apiPointName = 'getPublisherGeneralStatistics';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: { date_from, date_to, art_type },
      context,
    });
  },
);
