import {
  EResultType,
  filtersGroup,
} from 'components/complex/FiltersModerator/constans';
import { defaultModerationLimit } from 'components/complex/pages/moderation/constants';

import type { IAllQueueStateType } from './types';
import { EModerationBookTabs } from './types';

export const limit = defaultModerationLimit.toString();

export const initialState: IAllQueueStateType = {
  isFetched: false,
  isFetching: true,
  isFilterFetching: false,
  profiles: [],
  metaProfiles: null,
  moderators: [],
  metaModerators: null,
  allQueueBooksReturns: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  allQueueBooksNew: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  allQueueProfileReturns: {
    result: null,
    errors: [],
    type: EResultType.PROFILE,
  },
  allQueueProfileNew: {
    result: null,
    errors: [],
    type: EResultType.PROFILE,
  },
  myBooksQueueReturns: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  myBooksQueueNew: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  myQueueProfilesReturns: {
    result: null,
    errors: [],
    type: EResultType.PROFILE,
  },
  myQueueProfilesNew: {
    result: null,
    errors: [],
    type: EResultType.PROFILE,
  },
  finalQueueBooksReturns: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  finalQueueBooksNew: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  finalQueueBooksErrors: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  finalQueueBooksFreezed: {
    result: null,
    errors: [],
    type: EResultType.BOOK,
  },
  page: 'allBooks',
  tabName: EModerationBookTabs.allQueueBooksNew,
  counters: null,
  filtersCheckBoxGroup: filtersGroup,
  filtersAutocompleteGroup: {
    mailModerators: [],
    mailProfiles: [],
  },
  filtersSwitchers: {
    postmoderation: false,
  },
  moderationId: null,
  errors: [],
};

export enum ELicenseType {
  draft = 'draft',
  free = 'free',
  exclusive = 'exclusive',
  nonExclusive = 'non_exclusive',
  authorExclusive = 'author_exclusive',
}

export enum EProcessingTime {
  twentyFour = 'twenty_four',
  sixteen = 'sixteen',
  eight = 'eight',
  timeIsUp = 'time_is_up',
}

export enum EProfileStatus {
  /**
   * Юзер не отсылал профиль на модерацию
   * Дефолтное значение
   */
  new = 'NEW',

  /**
   * Юзер отправит (сохранил) профиль на ревью модератора
   * Статус с опечаткой с бекенда (кек)
   */
  submitted = 'SUBMITTED',

  /**
   * Профиль был взят на модераию, модератор отметил его в очереди
   */
  unverified = 'UNVERIFIED',

  /**
   * Модератор поставил статус "требует доработки" и добавил коммент, описывающий эти доработки
   * a comment describing the revisions
   */
  rejected = 'REJECTED',

  /**
   * Модератор проверил профиль и заапрувил его
   */
  verified = 'VERIFIED',
}

export enum EBookModerationState {
  new = 'NEW',
  submitted = 'SUBMITTED',
  unverified = 'UNVERIFIED',
  verified = 'VERIFIED',
  rejected = 'REJECTED',
  remoteDenied = 'REMOTE_DENIED',
  ready = 'READY',
  retrieved = 'RETRIEVED',
}

export enum EPostModerationStatus {
  notRequired = 'NOT_REQUIRED',
  submitted = 'SUBMITTED',
  unverified = 'UNVERIFIED',
}
