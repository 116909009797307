import { createAsyncThunk } from '@reduxjs/toolkit';

import type { IApiPointsFetchParamsType } from 'constants/apiPoints';

import { doFetch } from 'utils/doFetch';

export const restorePasswordThunk = createAsyncThunk(
  'userRegistration/password_reset',
  async ({
    email,
  }: IApiPointsFetchParamsType['restorePassword']['queryParams']) => {
    const apiPointName = 'restorePassword';

    return doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: {
        email: email,
      },
    });
  },
);
