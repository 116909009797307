import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import Router from 'next/router';

import { publisherUrls } from 'constants/pagesUrls';

import type { IHydrateAction } from 'sp-redux';
import { publisherLoginThunk } from 'sp-redux/thunks/publisher/publisherLogin';
import {
  getRoyaltyDataBySessionThunk,
  getReferralStatusByUserIdThunk,
  getUserInfoThunk,
  loginThunk,
  logoutThunk,
  verificationPasswordThunk,
} from 'sp-redux/thunks/userInfo';
import { checkRoyaltyOtcThunk } from 'sp-redux/thunks/userInfo/checkRoyaltyOtcThunk';
import { getRoyaltyInfoThunk } from 'sp-redux/thunks/userInfo/getRoyaltyInfoThunk';
import { sendRoyaltyOtcThunk } from 'sp-redux/thunks/userInfo/sendRoyaltyOtcThunk';

import type { UserInfoState } from './types';

const initialState: UserInfoState = {
  isLogged: false,
  is_moderator: false,
  is_publisher: false,
  profile: {},
  errors: [],
  royaltyInfo: {},
  referral: {},
  verificationPassword: {},
  sendRoyaltyOtc: {},
  checkRoyaltyOtc: {},
};

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setLitresAccountLinkBroken(state) {
      state.referral = { ...state.referral, isDataBroken: true };
    },
    setIsPublisher(state) {
      state.is_publisher = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      if (Object.keys(action.payload.result).length > 0) {
        state.isLogged = true;
      }
      state.profile = action.payload.result;
      state.errors = action.payload.errors;
    });

    builder.addCase(publisherLoginThunk.fulfilled, (state, action) => {
      if (Object.keys(action.payload.result).length > 0) {
        state.isLogged = true;
      }
      state.profile = action.payload.result;
      state.errors = action.payload.errors;
      state.is_publisher = true;
    });

    builder.addCase(logoutThunk.fulfilled, state => {
      // Add user to the state array
      // state.entities.push(action.payload);
      const isPublisher = state.is_publisher;
      if (isPublisher) {
        state.is_publisher = false;
        Router.replace(publisherUrls.login);
      } else {
        state.is_publisher = false;
        Router.reload();
      }
    });

    builder.addCase(getUserInfoThunk.fulfilled, (state, action) => {
      // бекенд отвечает user id === 0, когда юзер не залогинен
      // eslint-disable-next-line
      if (action.payload !== undefined) {
        if (action.payload.result.id === 0) {
          state.isLogged = false;
        } else {
          state.isLogged = true;
          state.profile = action.payload.result;
          state.is_publisher = action.payload.result.is_publisher || false;
        }
      }
    });

    builder.addCase(getRoyaltyDataBySessionThunk.fulfilled, (state, action) => {
      state.referral = action.payload;
    });

    builder.addCase(
      getReferralStatusByUserIdThunk.fulfilled,
      (state, action) => {
        state.profile.litres_referral = action.payload.result.litres_referral;
      },
    );

    builder.addCase(verificationPasswordThunk.fulfilled, (state, action) => {
      if (Object.keys(action.payload.result).length > 0) {
        state.errors = [];
        state.verificationPassword = action.payload.result;
      }

      if (action.payload.errors.length > 0) {
        state.errors = action.payload.errors;
      }
    });

    builder.addCase(sendRoyaltyOtcThunk.fulfilled, (state, action) => {
      if (action.payload.result.cache_ttl) {
        state.sendRoyaltyOtc = action.payload.result;
      }

      if (action.payload.errors.length > 0) {
        state.errors = action.payload.errors;
      }
    });

    builder.addCase(checkRoyaltyOtcThunk.fulfilled, (state, action) => {
      if (action.payload.result.token) {
        state.checkRoyaltyOtc = action.payload.result;
      }

      if (action.payload.errors.length > 0) {
        state.errors = action.payload.errors;
      }
    });

    builder.addCase(getRoyaltyInfoThunk.fulfilled, (state, action) => {
      if (Object.keys(action.payload.result).length > 0) {
        state.royaltyInfo = action.payload.result;
      }
    });
    builder.addCase(HYDRATE, (state, action: IHydrateAction<UserInfoState>) => {
      return action.payload.userInfo;
    });
  },
});

export const { setLitresAccountLinkBroken, setIsPublisher } =
  userInfoSlice.actions;

export const userInfoReducer = userInfoSlice.reducer;
