import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

interface ICirculationCostThunk {
  page_count: string;
  items_count: string;
  cover_type: string;
  promocode?: string;
  city?: string;
  postcode?: string;
  context?: GetServerSidePropsContext;
}

export const getCirculationCostThunk = createAsyncThunk(
  'circulation/cost',
  async ({
    page_count,
    items_count,
    cover_type,
    promocode,
    city,
    postcode,
    context,
  }: ICirculationCostThunk) => {
    const apiPointName = 'getCirculationCost';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: {
        page_count,
        items_count,
        cover_type,
        promocode,
        city,
        postcode,
      },
      context,
    });
  },
);
