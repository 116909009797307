import type { IDownloadInProgressFile } from 'entities/File/types';

export const dndFilesSorting = (
  audioFiles: IDownloadInProgressFile[],
): IDownloadInProgressFile[] => {
  return audioFiles
    .map(file => {
      return { ...file, id: String(file.id) };
    })
    .sort(function (a, b) {
      return a.ordering - b.ordering;
    });
};
