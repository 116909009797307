import { createAsyncThunk } from '@reduxjs/toolkit';

import type { IAudioBookCoverInfo } from 'sp-redux/slices/audioBookMeta/types';

import { doFetch } from 'utils/doFetch';

export const setAudioBookCoverThunk = createAsyncThunk(
  'bookMeta/setAudioCover',
  async ({
    audioId,
    body,
  }: {
    audioId: string;
    body: FormData | Partial<IAudioBookCoverInfo>;
  }) => {
    const apiPointName = 'setAudioBookCover';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: { audioId },
      body,
    });
  },
);
