import { createAsyncThunk } from '@reduxjs/toolkit';

import { doFetch } from 'utils/doFetch';

export const passportOfficesListThunk = createAsyncThunk(
  'document/passportOfficesList',
  async (passportCode: string) => {
    const apiPointName = 'passportInfo';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      body: {
        code: passportCode,
      },
    });
  },
);
