import { createAsyncThunk } from '@reduxjs/toolkit';

import { doFetch } from 'utils/doFetch';

export const takingProfileMyQueueThunk = createAsyncThunk(
  'moderation/takingProfileMyQueue',
  async (checkedProfiles: number[]) => {
    const apiPointName = 'takeProfileToModeration';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      body: {
        profile: checkedProfiles,
      },
    });
  },
);
