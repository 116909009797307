import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ESeriesState } from 'entities/Series/constant';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getSeriesThunk = createAsyncThunk(
  'series/getSeries',
  async ({
    limit,
    offset,
    status,
    bookId,
    context,
  }: {
    limit?: number;
    offset?: number;
    status?: ESeriesState;
    bookId?: number | string;
    context?: GetServerSidePropsContext;
  }) => {
    const apiPointName = 'getSeries';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      queryParams: {
        limit,
        offset,
        status,
        book_id: bookId,
      },
    });
  },
);
