import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';
import { NextPageContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IAllNotificationsThunkProps {
  context?: GetServerSidePropsContext | NextPageContext;
}

export const getAllNotificationsThunk = createAsyncThunk(
  'getAllNotifications',
  async ({ context }: IAllNotificationsThunkProps) => {
    const apiPointName = 'getAllNotifications';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
    });
  },
);
