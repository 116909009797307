import type { IPublicRuntimeConfig } from 'app.types';
import getConfig from 'next/config';

export const isBrowser = typeof window !== 'undefined';

export const isTestEnvironment = (): boolean => {
  const { publicRuntimeConfig } = getConfig();
  const { host }: IPublicRuntimeConfig = publicRuntimeConfig;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return !host?.includes('selfpub.ru');
};

export const isSelfpubUrl = (url: string): boolean => {
  const domain = /selfpub./g;
  return domain.test(url);
};
