import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { countries, documentTypes } from 'sp-redux/slices/document/constants';
import type { IDocumentState } from 'sp-redux/slices/document/types';
import { getDocumentThunk } from 'sp-redux/thunks/document/getDocument';
import { passportOfficesListThunk } from 'sp-redux/thunks/document/passportOfficesList';
import {
  uploadAdditionalScan,
  uploadDocumentsThunk,
  uploadDocumentThunk,
  uploadPhotoWithDocument,
} from 'sp-redux/thunks/document/uploadDocuments';

const initialState: IDocumentState = {
  isFetched: false,
  isFetching: true,
  document: {},
  passportOfficesList: [],
  errorsThunk: '',
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setResidentCountry: (state, action) => {
      if (action.payload) {
        state.document.resident_country = action.payload;
      }
    },
    setDocumentType: (state, action) => {
      if (action.payload) {
        state.document.type = action.payload;
      }
    },
    setSerialNumber: (state, action: PayloadAction<string>) => {
      state.document.serial_number = action.payload;
    },
    setWhomIssued: (state, action: PayloadAction<string>) => {
      state.document.whom_issued = action.payload;
    },
    setWhenIssued: (state, action: PayloadAction<string>) => {
      state.document.when_issued = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(getDocumentThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.isFetched = true;
        state.isFetching = false;

        const { result } = action.payload;

        const clientResidentCountry = countries.find(
          c => c.value === result.resident_country,
        ) ?? { label: 'Россия', value: 'RU' };
        const clientType = documentTypes.find(t => t.value === result.type) ?? {
          label: 'Паспорт',
          value: 'russian_passport',
        };

        state.document = {
          ...result,
          resident_country: clientResidentCountry,
          type: clientType,
          serial_number: result.serial_number ?? '',
          when_issued: result.when_issued ?? '',
        };
      }
    });

    builder.addCase(uploadPhotoWithDocument.fulfilled, (state, action) => {
      state.document.uploadedPhotoWithDocument = action.payload.file;
      state.document.photo_with_document = action.payload.image;
    });

    builder.addCase(uploadDocumentThunk.fulfilled, (state, action) => {
      state.document.uploadedDocument = action.payload.file;
      state.document.document_scan = action.payload.image;
    });

    builder.addCase(uploadDocumentsThunk.fulfilled, (state, action) => {
      state.document.uploadedDocument = action.payload[0].file;
      state.document.document_scan = action.payload[0].image;
      if (action.payload[1]) {
        state.document.uploadedAdditionalScan = action.payload[1].file;
        state.document.additional_scan = action.payload[1].image;
      }
    });

    builder.addCase(uploadAdditionalScan.fulfilled, (state, action) => {
      state.document.uploadedAdditionalScan = action.payload.file;
      state.document.additional_scan = action.payload.image;
    });

    builder.addCase(passportOfficesListThunk.fulfilled, (state, action) => {
      state.errorsThunk = '';
      if (Array.isArray(action.payload.result)) {
        state.passportOfficesList = action.payload.result.map(el => {
          return { value: el, label: el };
        });
      }

      if (action.payload.errors.length > 0) {
        state.errorsThunk = action.payload.errors[0].messages[0];
      }
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IDocumentState>) => {
        return action.payload.document;
      },
    );
  },
});

export const {
  setResidentCountry,
  setDocumentType,
  setSerialNumber,
  setWhomIssued,
  setWhenIssued,
} = documentSlice.actions;

export const documentReducer = documentSlice.reducer;
