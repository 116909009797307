import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IAPIResponse } from 'app.types';

import { doFetch } from 'utils/doFetch';

export type TLitresReferalStatusType =
  | 'undefined'
  | 'DISABLED'
  | 'OFFER'
  | 'ACTIVE'
  | 'PENDING';

export const getReferralStatusByUserIdThunk = createAsyncThunk(
  'userInfo/getAccountData',
  async (
    userId: string | number,
  ): Promise<
    IAPIResponse<{ litres_referral: TLitresReferalStatusType }, string>
  > => {
    const apiPointName = 'getAccountData';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: {
        userId,
      },
    });
  },
);
