import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetPodcastEmployeesByAudioIdSignature {
  podcastId: string;
  context?: GetServerSidePropsContext;
}

export interface IGetPodcastEmployeesByPodcastIdRequest {
  id: string;
}

export const getPodcastEmployeesByAudioIdThunk = createAsyncThunk(
  'podcasts/getPodcastEmployees',
  async ({ podcastId, context }: IGetPodcastEmployeesByAudioIdSignature) => {
    const apiPointName = 'getPodcastEmployees';
    if (podcastId) {
      return await doFetch<typeof apiPointName>({
        apiPointName,
        context,
        urlParams: { podcastId },
      });
    }
  },
);
