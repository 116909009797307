import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IAPIResponse } from 'app.types';

import { doFetch } from 'utils/doFetch';

const requestLogout = (): Promise<IAPIResponse<null, string>> => {
  const apiPointName = 'logout';
  return doFetch<typeof apiPointName>({
    apiPointName,
  });
};

export const logoutThunk = createAsyncThunk('userInfo/logout', requestLogout);
