import { createAsyncThunk } from '@reduxjs/toolkit';

import type { IApiPointsFetchParamsType } from 'constants/apiPoints';

import { doFetch } from 'utils/doFetch';

export const verificationPasswordThunk = createAsyncThunk(
  'userInfo/verificationPassword',
  async ({
    email,
    password,
  }: IApiPointsFetchParamsType['passwordRoyaltyValidation']['request']) => {
    const apiPointName = 'passwordRoyaltyValidation';
    return await doFetch<typeof apiPointName>({
      apiPointName,
      body: {
        email,
        password,
      },
    });
  },
);
