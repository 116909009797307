import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getBookCoverThunk = createAsyncThunk(
  'bookMeta/getCover',
  async ({
    bookId,
    context,
  }: {
    bookId: string;
    context?: GetServerSidePropsContext;
  }) => {
    const apiPointName = 'getBookCover';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { bookId },
    });
  },
);
