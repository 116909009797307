import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getCommentTemplatesThunk = createAsyncThunk(
  'commentTemplates/getCommentTemplates',
  async ({ context }: { context?: GetServerSidePropsContext }) => {
    const apiPointName = 'getCommentTemplates';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
    });
  },
);
