import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IPublisherReadingStatisticsThunkProps {
  date_from?: string;
  date_to?: string;
  art_type?: 'text' | 'audio';
  context?: GetServerSidePropsContext;
  limit?: number;
  offset?: number;
}

export const getPublisherReadingStatisticsThunk = createAsyncThunk(
  'statistics/getPublisherReadingStatistics',
  async ({
    date_from,
    date_to,
    art_type,
    context,
    limit,
    offset,
  }: IPublisherReadingStatisticsThunkProps) => {
    const apiPointName = 'getPublisherReadingStatistics';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: { date_from, date_to, art_type, limit, offset },
      context,
    });
  },
);
