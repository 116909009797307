import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetAudioBookModerationCommentsThunkSignature {
  audioId: string;
  context?: GetServerSidePropsContext;
}

export const getAudioBookModerationCommentsThunk = createAsyncThunk(
  'audioBooks/getAudioBookModerationComments',
  async ({
    audioId,
    context,
  }: IGetAudioBookModerationCommentsThunkSignature) => {
    const apiPointName = 'getAudioBookModerationComments';
    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { audioId },
    });
  },
);
