import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getAudioOwnersThunk = createAsyncThunk(
  'owners/getAudioOwners',
  async (context?: GetServerSidePropsContext) => {
    const apiPointName = 'getAudioOwners';
    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
    });
  },
);
