import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext, NextPageContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getSeriesCountThunk = createAsyncThunk(
  'series/count',
  async (context?: GetServerSidePropsContext | NextPageContext | undefined) => {
    const apiPointName = 'seriesCount';
    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
    });
  },
);
