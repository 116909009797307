import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetDraftBookChaptersThunkProps {
  bookId: string;
  context?: GetServerSidePropsContext;
}

export const getDraftBookChaptersThunk = createAsyncThunk(
  'bookMeta/getDraftBookChapters',
  async ({ bookId, context }: IGetDraftBookChaptersThunkProps) => {
    const apiPointName = 'getDraftBookChapters';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { bookId },
    });
  },
);
