import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getOwnersThunk = createAsyncThunk(
  'owners/getOwners',
  async (context?: GetServerSidePropsContext) => {
    const apiPointName = 'getOwners';
    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
    });
  },
);
