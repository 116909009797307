import { createAsyncThunk } from '@reduxjs/toolkit';
import { defaultLimit } from 'modules/statistics/pages/constants';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetRoyaltyStatisticsThunkProps {
  date_from: number;
  date_to: number;
  user_id?: string | string[];
  book_ids?: number[];
  offset?: number;
  context?: GetServerSidePropsContext;
}

export const getAudioRoyaltyStatisticsThunk = createAsyncThunk(
  'statistics/getAudioRoyaltyStatistics',
  async ({
    date_from,
    date_to,
    user_id,
    book_ids,
    offset,
    context,
  }: IGetRoyaltyStatisticsThunkProps) => {
    const apiPointName = 'getAudioRoyaltyStatistics';

    const queryFormatComma = true;

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: {
        date_from,
        date_to,
        user_id,
        book_ids,
        limit: defaultLimit,
        offset,
      },
      queryFormatComma,
      context,
    });
  },
);
