import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

interface IPatchCirculationOrderThunk {
  orderId: string;
  phone?: string;
  email?: string;
  items_count?: number;
  cover_type?: string;
  comment?: string;
  first_name?: string;
  last_name?: string;
  postcode?: string;
  region?: string;
  locality?: string;
  street?: string;
  building?: string;
  flat?: string;
  context?: GetServerSidePropsContext;
}

export const patchCirculationOrderThunk = createAsyncThunk(
  'orders/orderCirculation/patchCirculationOrder',
  async ({
    orderId,
    phone,
    email,
    items_count,
    cover_type,
    comment,
    first_name,
    last_name,
    postcode,
    region,
    locality,
    street,
    building,
    flat,
    context,
  }: IPatchCirculationOrderThunk) => {
    const apiPointName = 'patchCirculationOrder';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: { orderId },
      body: {
        phone,
        email,
        items_count,
        cover_type,
        comment,
        first_name,
        last_name,
        postcode,
        region,
        locality,
        street,
        building,
        flat,
      },
      context,
    });
  },
);
