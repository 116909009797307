import { createAsyncThunk } from '@reduxjs/toolkit';

import { doFetch } from 'utils/doFetch';

export const getRoyaltyDataBySessionThunk = createAsyncThunk(
  'userInfo/getRoyaltyDataBySession',

  async () => {
    const apiPointName = 'getRoyaltyDataBySession';

    let isDataBroken = false;
    let totalRoyalty = null;
    let availableRoyalty = null;

    try {
      const { result, errors } = await doFetch<typeof apiPointName>({
        apiPointName,
      });

      if (errors.length > 0) {
        isDataBroken = true;
      } else {
        totalRoyalty = result.total_sum;
        availableRoyalty = result.amount;
      }
    } catch (error) {
      isDataBroken = true;
    }

    return Promise.resolve({
      isDataBroken,
      totalRoyalty,
      availableRoyalty,
    });
  },
);
