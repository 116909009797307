import { ID_YANDEX_METRIKA } from 'analytics/const';

import { isBrowser } from 'utils/environment';

interface IGoogleAnalytics {
  action: string;
  name?: string;
  category?: string;
  label?: string;
}

interface IYandexAnalytics {
  action: string;
  goalParams: {
    user_id: string; // user uuid
    [key: string]: string | number | boolean;
  };
}

export const sendGoogleAnalytics = ({
  action,
  name,
  category,
  label,
}: IGoogleAnalytics): void => {
  const eventObject = {
    hitType: 'event',
    event: name,
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  };

  if (isBrowser && typeof window.ga === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.ga('send', eventObject);
    // eslint-disable-next-line no-console
    console.info('Send ga event', eventObject);
  }
};

export const sendYandexAnalytics = ({
  action,
  goalParams,
}: IYandexAnalytics): void => {
  if (isBrowser && typeof window.ym === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.ym(ID_YANDEX_METRIKA, 'reachGoal', action, goalParams);
    // eslint-disable-next-line no-console
    console.info('Send ya event', action, goalParams);
  }
};
