import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext, NextPageContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getUserInfoThunk = createAsyncThunk(
  'userInfo/getUserInfo',
  async (context?: GetServerSidePropsContext | NextPageContext) => {
    const apiPointName = 'userInfo';

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const queryParams: { _atoken?: string } = {};

    const { query } = context || {};

    const { _atoken: authToken } = query || {};

    if (typeof authToken === 'string') {
      queryParams._atoken = authToken;
    }

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      queryParams,
    });
  },
);
