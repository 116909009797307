import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetAudioBookMetaThunkSignature {
  bookId: string;
  context?: GetServerSidePropsContext;
}

export const getAudioBookMetaByBookIdThunk = createAsyncThunk(
  'audioBooks/getAudioBookMetaByBookId',
  async ({ bookId, context }: IGetAudioBookMetaThunkSignature) => {
    const apiPointName = 'getAudioBookMetaByBookId';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { bookId },
    });
  },
);
