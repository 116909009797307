import { createAsyncThunk } from '@reduxjs/toolkit';
import type { TArtType } from 'entities/Art/types';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getBookDiscountsThunk = createAsyncThunk(
  'bookMeta/getBookDiscounts',
  async ({
    bookId,
    art_type,
    context,
  }: {
    bookId: string;
    art_type: TArtType;
    context?: GetServerSidePropsContext;
  }) => {
    const apiPointName = 'getBookDiscountsInfo';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: { bookId },
      queryParams: { art_type },
      context,
    });
  },
);
