import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

interface IGetServiceTypesGroupProps {
  group_type: string;
  context?: GetServerSidePropsContext;
}

export const getServiceTypesGroup = createAsyncThunk(
  'services/getServiceTypesGroup',
  async ({ group_type, context }: IGetServiceTypesGroupProps) => {
    const apiPointName = 'getServiceTypesGroup';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      queryParams: { group_type },
    });
  },
);
