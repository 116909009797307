import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getMyBooksCount = createAsyncThunk(
  'myBooks/myBooksCount',
  async ({ context }: { context?: GetServerSidePropsContext }) => {
    const apiPointName = 'myBooksCount';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
    });
  },
);
