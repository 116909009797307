export interface IUtmParams {
  source: string;
  medium: string;
  campaign?: string;
  content?: string;
  term?: string;
}

export function createUTMUrl(url: string, utmParams: IUtmParams): string {
  const { source, medium, campaign, content, term } = utmParams;
  let utmString = `utm_source=${source}&utm_medium=${medium}`;

  if (campaign) {
    utmString += `&utm_campaign=${campaign}`;
  }

  if (content) {
    utmString += `&utm_content=${content}`;
  }

  if (term) {
    utmString += `&utm_term=${term}`;
  }

  if (url.endsWith('/')) {
    return `${url}?${utmString}`;
  }

  if (url.includes('?')) {
    return `${url}&${utmString}`;
  }

  return `${url}/?${utmString}`;
}
