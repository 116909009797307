import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface ILegacyGetMyAudioBooksThunk {
  limit?: number;
  offset?: number;
  employee?: number;
  context?: GetServerSidePropsContext;
  is_published?: boolean;
}

export const getMyAudioBooksThunk = createAsyncThunk(
  'myAudioBooks/getMyAudioBooks',
  async ({
    context,
    limit,
    offset,
    employee,
    is_published,
  }: ILegacyGetMyAudioBooksThunk) => {
    const apiPointName = 'myAudioBooks';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      queryParams: {
        limit,
        offset,
        employee,
        is_published,
      },
    });
  },
);
