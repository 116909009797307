import { createSlice } from '@reduxjs/toolkit';

import { registrationThunk } from 'sp-redux/thunks/userRegistration/registrationThunk';
import { restorePasswordThunk } from 'sp-redux/thunks/userRegistration/restorePasswordThunk';

import type { IRegistrationState } from './types';

const initialState: IRegistrationState = {
  isFetched: false,
  isFetching: false,
  result: {},
  errors: [],
};

const userRegistrationSlice = createSlice({
  name: 'userRegistration',
  initialState,
  reducers: {
    clearErrors: state => {
      state.errors = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(registrationThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;
      state.result = action.payload.result;
      state.errors = action.payload.errors;
    });

    builder.addCase(registrationThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(restorePasswordThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;
      state.result = action.payload.result;
      state.errors = action.payload.errors;
    });

    builder.addCase(restorePasswordThunk.pending, state => {
      state.isFetching = true;
    });
  },
});

export const { clearErrors } = userRegistrationSlice.actions;

export const userRegistrationReducer = userRegistrationSlice.reducer;
