import { createAsyncThunk } from '@reduxjs/toolkit';
import type { EMyBooksFilters } from 'entities/MyBook/constants';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IMyDraftBooksThunkProps {
  limit?: number;
  offset?: number;
  status?: EMyBooksFilters;
  user_id?: string | string[];
  has_statistics?: boolean;
  is_free?: boolean;
  context?: GetServerSidePropsContext;
}

export const getMyDraftBooksThunk = createAsyncThunk(
  'myBooks/getMyDraftBooks',
  async ({
    context,
    limit,
    offset,
    status,
    user_id,
    has_statistics,
    is_free,
  }: IMyDraftBooksThunkProps) => {
    const apiPointName = 'myBooksDraftBooks';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      queryParams: {
        limit,
        offset,
        status,
        has_statistics,
        is_free,
        user_id,
      },
    });
  },
);
