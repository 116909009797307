import { createAsyncThunk } from '@reduxjs/toolkit';

import { doFetch } from 'utils/doFetch';

export const deleteSeriesThunk = createAsyncThunk(
  'series/delete',
  async (seriesId: number) => {
    const apiPointName = 'deleteSeries';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: { seriesId },
    });
  },
);
