import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGerEmployeesRolesForBookThunkSignature {
  bookId: string;
  type_id?: number;
  bookType: string;
  context?: GetServerSidePropsContext;
}

export const getEmployeesRolesForBookThunk = createAsyncThunk(
  'bookMeta/getEmployeesRolesForBookThunk',
  async ({
    bookId,
    type_id,
    context,
    bookType,
  }: IGerEmployeesRolesForBookThunkSignature) => {
    const apiPointName = 'getEmployeesRolesForBook';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      queryParams: {
        art_id: bookId,
        type_id: type_id,
        art_type: bookType,
      },
    });
  },
);
