import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getMyBooksThunk = createAsyncThunk(
  'myBooks/getMyBooks',
  async ({
    context,
    limit,
    offset,
    employee,
    is_published,
    o,
    name,
  }: {
    limit?: number;
    offset?: number;
    employee?: number;
    context?: GetServerSidePropsContext;
    is_published?: boolean;
    o?: string;
    name?: string;
  }) => {
    const apiPointName = 'myBooks';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      queryParams: {
        limit,
        offset,
        employee,
        is_published,
        o,
        name,
      },
    });
  },
);
