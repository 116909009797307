import type { IAutocompleteOptionType } from 'components/UIKit/AutocompleteSelect';

export interface IAbstractCompletableEntity {
  id: string | number;
  name: string;
}

export const mapToAutocompleteItems = (
  items: IAbstractCompletableEntity[],
): IAutocompleteOptionType[] => {
  const pars: { label: string; value: string | number }[] = [];
  items.forEach(item => {
    if (item.name !== 'Самиздат') {
      pars.push({
        label: item.name,
        value: item.id,
      });
    }
  });
  return pars;
};

export const mapFromAutocompleteItemsToEntityIdsList = (
  items: IAutocompleteOptionType[],
): (string | number)[] => {
  return items.map(item => item.value);
};

export function mapFromAutocompleteItemsToEntity(
  items: IAutocompleteOptionType[],
): IAbstractCompletableEntity[] {
  return items.map(item => ({ id: item.value, name: item.label }));
}
