import { createSlice } from '@reduxjs/toolkit';
import { ESeriesStatus } from 'entities/Series/constant';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux/index';
import { editSeriesThunk } from 'sp-redux/thunks/series/editSeries';
import { getSeriesDetailsThunk } from 'sp-redux/thunks/series/getSeriesDetails';

import type { ISeriesDetailsStateType } from './types';

const initialState: ISeriesDetailsStateType = {
  isFetched: false,
  isFetching: false,
  series: {
    id: 0,
    name: '',
    description: '',
    status: ESeriesStatus.not_published,
    count: {
      total: 0,
      books: 0,
      audiobooks: 0,
      drafts: 0,
    },
    is_ordered: true,
    is_editable: true,
    covers: [],
    items: [],
    art_list: [],
  },
};

const seriesDetailsSlice = createSlice({
  name: 'seriesDetails',
  initialState,
  reducers: {
    editSeriesSuccess: (state, action) => {
      state.isFetched = true;
      state.isFetching = false;
      state.series = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(getSeriesDetailsThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(editSeriesThunk.pending, state => {
      state.isFetching = true;
    });
    builder.addCase(editSeriesThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      state.series = action.payload.result;
    });

    builder.addCase(getSeriesDetailsThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      state.series = action.payload.result;
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<ISeriesDetailsStateType>) => {
        return action.payload.seriesDetails;
      },
    );
  },
});

export const { editSeriesSuccess } = seriesDetailsSlice.actions;

export const seriesDetailsReducer = seriesDetailsSlice.reducer;
