import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IPersonsListThunkProps {
  has_litres_person_id: boolean;
  context?: GetServerSidePropsContext;
}

export const getPersonsThunk = createAsyncThunk(
  'persons/getPersons',
  async ({ has_litres_person_id, context }: IPersonsListThunkProps) => {
    const apiPointName = 'getPersonsList';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      queryParams: {
        has_litres_person_id,
      },
    });
  },
);
