import type { FC } from 'react';
import { ToastContainer as ReactToastifyToastContainer } from 'react-toastify';
import type { ToastContainerProps } from 'react-toastify';

import s from './Toast.module.scss';

export const ToastContainer: FC<ToastContainerProps> = props => {
  const config: ToastContainerProps = {
    position: 'bottom-center',
    autoClose: 4000,
    hideProgressBar: true,
    newestOnTop: true,
    ...props,
  };
  return (
    <ReactToastifyToastContainer {...config} className={s.toastContainer} />
  );
};
