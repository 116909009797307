import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IBookCoverInfo } from 'entities/Cover/types';

import { doFetch } from 'utils/doFetch';

export const setBookCoverThunk = createAsyncThunk(
  'bookMeta/setCover',
  async ({
    bookId,
    body,
  }: {
    bookId: number;
    body: Partial<IBookCoverInfo>;
  }) => {
    const apiPointName = 'setBookCover';
    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: { bookId },
      body,
    });
  },
);
