import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IPostCreateAndSendThunkProps {
  book_id: number;
  building: string;
  email: string;
  first_name: string;
  flat: string;
  items_count: number;
  last_name: string;
  locality: string;
  phone: string;
  postcode: string;
  promo_code: string;
  region: string;
  street: string;
  comment: string;
  cover_type: string;
  context?: GetServerSidePropsContext;
}

export const postCreateAndSendThunk = createAsyncThunk(
  'services/circulation/create_and_send',
  async ({
    book_id,
    building,
    email,
    first_name,
    flat,
    items_count,
    last_name,
    locality,
    phone,
    postcode,
    promo_code,
    region,
    street,
    comment,
    cover_type,
    context,
  }: IPostCreateAndSendThunkProps) => {
    const apiPointName = 'postCirculationCreateAndSend';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      body: {
        book_id,
        building,
        email,
        first_name,
        flat,
        items_count,
        last_name,
        locality,
        phone,
        postcode,
        promo_code,
        region,
        street,
        comment,
        cover_type,
      },
    });
  },
);
