import { EBooksTypes } from 'modules/statistics/pages/types';

import type { ISelectOptionType } from 'components/UIKit/SelectBasic/types';

const DAYS_IN_A_WEEK = 7;
const THIRTY_DAYS = 30;
const NINETY_DAYS = 90;

export const STATISTICS_DATE_RANGE = {
  allTime: (): string =>
    [new Date().setFullYear(new Date().getFullYear() - 5)].toString(),
  lastWeek: (): string =>
    [new Date().setDate(new Date().getDate() - DAYS_IN_A_WEEK)].toString(),
  lastThirtyDays: (): string =>
    [new Date().setDate(new Date().getDate() - THIRTY_DAYS)].toString(),
  lastNinetyDays: (): string =>
    [new Date().setDate(new Date().getDate() - NINETY_DAYS)].toString(),
  currentMonth: (): string => [new Date().setDate(1)].toString(),
};

export const DATES: ISelectOptionType[] = [
  { value: STATISTICS_DATE_RANGE.allTime(), label: 'Все время' },
  {
    value: STATISTICS_DATE_RANGE.lastWeek(),
    label: 'Последние 7 дней',
  },
  {
    value: STATISTICS_DATE_RANGE.lastThirtyDays(),
    label: 'Последние 30 дней',
  },
  {
    value: STATISTICS_DATE_RANGE.lastNinetyDays(),
    label: 'Последние 90 дней',
  },
  {
    value: STATISTICS_DATE_RANGE.currentMonth(),
    label: 'Текущий месяц',
  },
];

export const dashboardTooltipTexts: Record<
  string,
  Record<EBooksTypes, string>
> = {
  salesCount: {
    book: 'Количество скачиваний ваших книг в Литрес и других книжных интернет-магазинах. Учитываются скачивания как платных, так и бесплатных книг.',
    audiobook:
      'Количество скачиваний ваших аудиокниг в Литрес и других книжных интернет-магазинах. Учитываются скачивания как платных, так и бесплатных аудиокниг.',
    printed_book: '',
  },
  bookCardViewsCount: {
    book: 'Общее количество уникальных просмотров страниц ваших книг читателями на Литрес.',
    audiobook:
      'Общее количество уникальных просмотров страниц ваших аудиокниг читателями на Литрес.',
    printed_book: '',
  },
  reviewsCount: {
    book: 'Общее количество полученных отзывов на ваши книги на Литрес.',
    audiobook: 'Общее количество полученных отзывов на ваши книги на Литрес.',
    printed_book: '',
  },
  readingDepth: {
    book: 'Значение глубины прочтения ваших книг читателями в % отображает, до какой части в среднем дочитывают пользователи книгу на текущий момент.',
    audiobook:
      'Значение глубины прослушивания ваших книг читателями в % отображает, до какой части в среднем дослушивают пользователи аудиокнигу на текущий момент.',
    printed_book: '',
  },
};
