import { createAsyncThunk } from '@reduxjs/toolkit';
import { editSeriesRequest } from 'modules/series/helpers/editSeriesRequest';

import type { IEditSeriesAPIRequest } from 'sp-redux/slices/seriesDetails/types';

export const editSeriesThunk = createAsyncThunk(
  'series/edit',
  async ({ id, art_list, context }: IEditSeriesAPIRequest) => {
    return await editSeriesRequest({
      id,
      art_list,
      context,
    });
  },
);
