import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux/index';
import { getAudioOwnersThunk } from 'sp-redux/thunks/audioOwners/getAudioOwnersThunk';
import { getOwnersThunk } from 'sp-redux/thunks/owners/getOwnersThunk';

import type { IOwnersState } from './types';

const initialState: IOwnersState = {
  ebook: [],
  audio: [],
};

const OwnersSlice = createSlice({
  name: 'owners',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getOwnersThunk.fulfilled, (state, action) => {
      state.ebook = action.payload.result;
    });

    builder.addCase(getAudioOwnersThunk.fulfilled, (state, action) => {
      state.audio = action.payload.result;
    });

    builder.addCase(HYDRATE, (state, action: IHydrateAction<IOwnersState>) => {
      return action.payload.owners;
    });
  },
});

export const ownersReducer = OwnersSlice.reducer;
