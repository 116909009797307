import createDebug from 'debug';
import { GetServerSidePropsContext } from 'next';

import type { IRating } from 'sp-redux/slices/bookMeta/types';

import { isTestEnvironment } from 'utils/environment';
import { fetchWrapper } from 'utils/fetchWrapper';

const debug = createDebug('Foundation:fetch');

interface IFetchArtRatingResponse {
  status: number;
  error: string | null;
  payload: {
    data: {
      rating: IRating;
    };
  };
}

export const fetchArtRating = async (
  prodUrl: string,
  stageUrl: string,
  litresArtId: number,
  context?: GetServerSidePropsContext,
): Promise<IFetchArtRatingResponse> => {
  const fetchUrl = isTestEnvironment()
    ? `${stageUrl}${litresArtId}`
    : `${prodUrl}${litresArtId}`;

  const options: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
    credentials: 'include',
  };

  const response = await fetchWrapper(fetchUrl, options, context);

  debug(`[${response.status}] ${fetchUrl}`);
  return await response.json();
};
