import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetPodcastModerationCommentsThunkSignature {
  podcastId: string;
  context?: GetServerSidePropsContext;
}

export const getPodcastModerationCommentsThunk = createAsyncThunk(
  'podcasts/getPodcastModerationComments',
  async ({
    podcastId,
    context,
  }: IGetPodcastModerationCommentsThunkSignature) => {
    const apiPointName = 'getPodcastModerationComments';
    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { podcastId },
    });
  },
);
