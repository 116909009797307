import { createSlice } from '@reduxjs/toolkit';
import { mapEmployeeRoleEntityToEmployeesRolesFormatted } from 'entities/Employee/helpers/mapRolesTypesToEmployeesRoles';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getEmployeesThunk } from 'sp-redux/thunks/employees/getEmployees';
import { getEmployeesRoleTypesThunk } from 'sp-redux/thunks/employees/getEmployeesRoleTypesThunk';

import type { IEmployeesStateType } from './types';

const initialState: IEmployeesStateType = {
  isFetched: false,
  isFetching: true,
  employees: [],
  employeesRoles: {},
};

const EmployeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getEmployeesThunk.pending, state => {
      state.isFetched = false;
      state.isFetching = true;
    });
    builder.addCase(getEmployeesThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;
      state.employees = action.payload.result;
    });

    builder.addCase(getEmployeesRoleTypesThunk.pending, state => {
      state.isFetched = false;
      state.isFetching = true;
    });
    builder.addCase(getEmployeesRoleTypesThunk.fulfilled, (state, action) => {
      state.employeesRoles = mapEmployeeRoleEntityToEmployeesRolesFormatted(
        action.payload.result,
      );
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IEmployeesStateType>) => {
        return action.payload.employees;
      },
    );
  },
});

export const employeesReducer = EmployeesSlice.reducer;
