import type { IEmployeeRoleFormatted, IEmployeeRoleEntity } from '../types';

export const mapEmployeeRoleEntityToEmployeesRolesFormatted = (
  rolesTypes: IEmployeeRoleEntity[],
): Record<string, IEmployeeRoleFormatted> => {
  let rolesObject = {};
  rolesTypes.forEach(
    role =>
      (rolesObject = {
        ...rolesObject,
        [role.slug]: { id: role.id, name: role.name },
      }),
  );
  return rolesObject;
};
