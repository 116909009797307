import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getBookPriceThunk } from 'sp-redux/thunks/bookPrice/getBookPrice';

import { MIN_BOOK_PRICE } from './constants';
import type { IBookPriceState } from './types';

const initialState: IBookPriceState = {
  bookPrices: [],
  isFetched: false,
  isFetching: false,
};

const bookPriceSlice = createSlice({
  name: 'bookPrice',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(getBookPriceThunk.pending, state => {
      return {
        ...state,
        isFetching: true,
      };
    });
    builder.addCase(getBookPriceThunk.fulfilled, (state, action) => {
      if (Array.isArray(action.payload.result)) {
        const bookPrices = action.payload.result.filter(
          item => parseFloat(item.value) >= MIN_BOOK_PRICE,
        );

        return {
          isFetched: true,
          isFetching: false,
          bookPrices,
        };
      }
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IBookPriceState>) => {
        return action.payload.bookPrice;
      },
    );
  },
});

export const BookPriceReducer = bookPriceSlice.reducer;
