import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IPublisherSalesStatisticsThunkV2Props {
  date_from?: string;
  date_to?: string;
  context?: GetServerSidePropsContext;
  limit?: number;
  offset?: number;
}

export const getPublisherSalesStatisticsV2Thunk = createAsyncThunk(
  'statistics/getPublisherSalesStatisticsV2',
  async ({
    date_from,
    date_to,
    context,
    limit,
    offset,
  }: IPublisherSalesStatisticsThunkV2Props) => {
    const apiPointName = 'getPublisherSalesStatisticsV2';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: { date_from, date_to, limit, offset },
      context,
    });
  },
);
