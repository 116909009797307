import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetAudioBookMetaThunkSignature {
  bookId: string;
  context?: GetServerSidePropsContext;
}

export interface IGetAudioBookAudioFilesRequest {
  id: string;
}

export const getAudioBookAudioFilesThunk = createAsyncThunk(
  'audioBooks/getAudioBookAudioFiles',
  async ({ bookId, context }: IGetAudioBookMetaThunkSignature) => {
    const apiPointName = 'getAudioBookAudioFiles';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { bookId },
    });
  },
);
