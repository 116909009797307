import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IPublisherReadingStatisticsThunkV2Props {
  date_from?: string;
  date_to?: string;
  context?: GetServerSidePropsContext;
  limit?: number;
  offset?: number;
  rh_id?: number;
}

export const getPublisherReadingStatisticsV2Thunk = createAsyncThunk(
  'statistics/getPublisherReadingStatisticsV2',
  async ({
    date_from,
    date_to,
    rh_id,
    context,
    limit,
    offset,
  }: IPublisherReadingStatisticsThunkV2Props) => {
    const apiPointName = 'getPublisherReadingStatisticsV2';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: { date_from, date_to, rh_id, limit, offset },
      context,
    });
  },
);
