import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { TPgRateAgeType } from 'entities/Age/types';
import type { IEmployee } from 'entities/Employee/types';
import type { IPodcast } from 'entities/Podcast/types';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getPodcastEmployeesByAudioIdThunk } from 'sp-redux/thunks/podcasts/getPodcastEmployeesByPodcastId';
import { getPodcastMetaByPodcastId } from 'sp-redux/thunks/podcasts/getPodcastMetaByPodcastId';
import { getPodcastModerationCommentsThunk } from 'sp-redux/thunks/podcasts/getPodcastModerationCommentsThunk';

import type { IPodcastMeta } from './types';

const initialState: IPodcastMeta = {
  isFetching: true,
  isFetched: false,
  hasLgbtConflict: false,
  meta: {},
  employees: [],
  moderationComments: [],
};

const PodcastMetaSlice = createSlice({
  name: 'podcastMeta',
  initialState,
  reducers: {
    setPodcastTitle(state, action) {
      state.meta.name = action.payload;
    },

    setPodcastRssUrl(state, action) {
      state.meta.rss_url = action.payload;
    },

    setPodcastGenres(state, action) {
      state.meta.genres = action.payload;
    },

    setPodcastTags(state, action) {
      state.meta.tags = action.payload;
    },

    setPodcastEmployees: (state, action: PayloadAction<IEmployee[]>) => {
      state.employees = action.payload;
    },

    setPodcastAgeRate: (state, action: PayloadAction<TPgRateAgeType>) => {
      state.meta.age = action.payload;
    },

    setPodcastLgbtMark: (state, action: PayloadAction<boolean>) => {
      state.meta.lgbt_mark = action.payload;
    },

    setPodcastLgbtConflicts: (state, action: PayloadAction<boolean>) => {
      state.hasLgbtConflict = action.payload;
    },

    setPodcastLgbtRulesAccepted: (state, action: PayloadAction<boolean>) => {
      state.meta.lgbt_rules_accepted = action.payload;
    },

    setPodcastObsceneLanguage: (state, action: PayloadAction<boolean>) => {
      state.meta.obscene_language = action.payload;
    },
    setPodcastMeta: (state, action: PayloadAction<IPodcast>) => {
      state.meta = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPodcastMetaByPodcastId.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getPodcastMetaByPodcastId.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;
      state.meta = action.payload.result;
      if (action.payload.result.lgbt_mark) {
        state.meta.lgbt_rules_accepted = true;
        state.hasLgbtConflict = false;
      }
    });

    builder.addCase(getPodcastEmployeesByAudioIdThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(
      getPodcastEmployeesByAudioIdThunk.fulfilled,
      (state, action) => {
        state.isFetched = true;
        state.isFetching = false;
        if (action.payload?.result) {
          state.employees = action.payload.result.employees;
        }
      },
    );

    builder.addCase(getPodcastModerationCommentsThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(
      getPodcastModerationCommentsThunk.fulfilled,
      (state, action) => {
        state.isFetched = true;
        state.isFetching = false;
        // Временное решение так как пока модераторы могут прислать только один коммент
        if (action.payload.result.comment) {
          state.moderationComments = [action.payload.result.comment];
        }
      },
    );

    builder.addCase(HYDRATE, (state, action: IHydrateAction<IPodcastMeta>) => {
      return action.payload.podcastMeta;
    });
  },
});

export const {
  setPodcastTitle,
  setPodcastRssUrl,
  setPodcastGenres,
  setPodcastTags,
  setPodcastEmployees,
  setPodcastAgeRate,
  setPodcastLgbtMark,
  setPodcastLgbtConflicts,
  setPodcastLgbtRulesAccepted,
  setPodcastObsceneLanguage,
  setPodcastMeta,
} = PodcastMetaSlice.actions;

export const podcastMetaReducer = PodcastMetaSlice.reducer;
