import type { IAPIResponse, IApiResponseErrorField } from 'app.types';
import type { IModeratorProfile } from 'entities/Profile/types';

import type { EResultType } from 'components/complex/FiltersModerator/constans';
import type {
  IRequestMetaModeratorsTypes,
  IModeratorsAutocompleteTypes,
} from 'components/complex/ModeratorsInput/types';
import type {
  IRequestMetaProfilesTypes,
  IProfileAutocompleteTypes,
} from 'components/complex/ProfilesInput/types';

import type { ETabType } from 'sp-redux/slices/ui/types';

import type { IModerationComment } from '../bookMeta/types';
import type { EAccountRoyaltyOfferStatus } from '../userInfo/types';
import type {
  EBookModerationState,
  EPostModerationStatus,
  ELicenseType,
  EProcessingTime,
  EProfileStatus,
} from './constants';

export interface IModeratorPageRenderTypes {
  page: 'allBooks' | 'allProfiles' | 'myBooks' | 'myProfiles' | 'finalBooks';
}

export enum EModerationBookTabs {
  allQueueBooksNew = 'allQueueBooksNew',
  allQueueBooksReturns = 'allQueueBooksReturns',
  myBooksQueueNew = 'myBooksQueueNew',
  myBooksQueueReturns = 'myBooksQueueReturns',
  finalQueueBooksReturns = 'finalQueueBooksReturns',
  finalQueueBooksNew = 'finalQueueBooksNew',
  finalQueueBooksErrors = 'finalQueueBooksErrors',
  finalQueueBooksFreezed = 'finalQueueBooksFreezed',
}

export enum EModerationProfileTabs {
  allQueueProfileNew = 'allQueueProfileNew',
  allQueueProfileReturns = 'allQueueProfileReturns',
  myQueueProfilesNew = 'myQueueProfilesNew',
  myQueueProfilesReturns = 'myQueueProfilesReturns',
}

export interface IAllQueueModerationResponseTypes {
  id: number;
  ebook_status: number;
  updated_at: string;
  sent_to_moderation: string;
  cover: ICover;
  user: IUser;
  force_moderation: boolean;
  is_postmoderation: boolean;
  postmoderation_status: EPostModerationStatus;
  plagiarism: IPlagiarism;
  nearest_chapter_publication_date: number | null;
  name: string;
  license_type: ELicenseType;

  moderator_email: string;
  who_returned_group: 'main_moderator' | 'moderator';
  processing_time: EProcessingTime;
  is_imported: boolean;
}

export interface IFilteringQueryParamsType {
  license_type?: ELicenseType[];
}

export interface IMyQueueModerationResponseTypes {
  id: number;
  book: IAllQueueModerationResponseTypes;
  is_preorder: boolean;
  status: string;
  moderation_comments: string[];
  moderator_email: string;
  who_returned_group: 'main_moderator' | 'moderator';
  ebook_status: number;
  updated_at: string;
  cover: ICover;
  user: IUser;
  nearest_chapter_publication_date: number | null;
  force_moderation: boolean;
  is_postmoderation: boolean;
  postmoderation_status: EPostModerationStatus;
  plagiarism: IPlagiarism;
  name: string;
  license_type: ELicenseType;
  processing_time: EProcessingTime;
  sent_to_moderation: string;
  is_imported: boolean;
}

export interface ICover {
  ebook_image_url: string;
  printed_image_rgb_url: string;
  status: string;
  processing_status: string;
}

export interface IPlagiarism {
  plagiarism_status: string;
  plagiarism_result: IPlagiarismResult;
  plagiarism_verdict: 'protected' | 'free' | 'unknown' | 'public_domain';
}

export interface IPlagiarismResult {
  author: string;
  name_book: string;
  url: string;
  text: string;
}

export interface IUser {
  status_profile_api:
    | 'UnfilledName'
    | 'Confirmed'
    | 'Star'
    | 'Unconfirmed'
    | 'UnfilledData';
  id: number;
  short_name: string;
  email: string;
  status: 'NEW' | 'SUBMITTED' | 'UNVERIFIED' | 'VERIFIED' | 'REJECTED';
  weight: number;
  agreement_type: EAgreementType;
}

export interface IPaginationTypes {
  count: number;
  next_page: string;
  previous_page: string;
}

export interface IMyBookTypes {
  id: number;
  ebook_status: number;
  force_moderation: boolean;
  is_postmoderation: boolean;
  postmoderation_status: EPostModerationStatus;
  name: string;
  annotation: string;
  age: number;
  obscene_language: boolean;
  date_written: number;
  authors_sheets: null | number;
  update_period: null | number;
  genres: number[];
  tags: number[];
}

export interface IModerationCommentTypes {
  moderation_book_id: number;
  comment_template: null | string;
  status: string;
  comment: string;
}

export interface IMyBooksModerationResponseTypes {
  id: number;
  book: IMyBookTypes;
  is_preorder: boolean;
  status: string;
  moderation_comments: IModerationCommentTypes[] | [];
}

//Одинаковые типы для New и Return
export interface IAllQueueProfileTypes {
  id: number;
  status: string;
  email: string;
  full_name: string;
  books_on_moderation: number;
  referral_name: string;
  date_modified: string;
  moderator_email: string;
  moderator_type: 'main_moderator' | 'moderator';
  who_returned_group: 'main_moderator' | 'moderator';
  processing_time: EProcessingTime;
  ebook_status: number;
  updated_at: string;
  nearest_chapter_publication_date: number | null;
  cover: ICover;
  user: IUser;
  force_moderation: boolean;
  is_postmoderation: boolean;
  postmoderation_status: EPostModerationStatus;
  plagiarism: IPlagiarism;
  name: string;
  license_type: ELicenseType;
}

export interface IMyQueueProfilesTypes {
  id: string;
  profile: IModeratorProfile;
  processing_time: EProcessingTime;
  last_moderator_email: null; //null в Swagger.email последнего модератора
  ebook_status: number;
  updated_at: string;
  cover: ICover;
  user: IUser;
  force_moderation: boolean;
  is_postmoderation: boolean;
  postmoderation_status: EPostModerationStatus;
  nearest_chapter_publication_date: number | null;
  plagiarism: IPlagiarism;
  name: string;
  license_type: ELicenseType;
  moderator_email: string;
  who_returned_group: 'main_moderator' | 'moderator';
}

export interface IFinalBookTypes {
  id: number;
  ebook_status: number;
  force_moderation: boolean;
  is_postmoderation: boolean;
  postmoderation_status: EPostModerationStatus;
  name: string;
  annotation: string;
  age: number;
  obscene_language: boolean;
  date_written: number;
  authors_sheets: null | number;
  update_period: null | string;
  genres: number[];
  tags: number[];
}

export interface IFinalModerationCommentTypes {
  moderation_book_id: number;
  comment_template: null | string;
  status: string;
  comment: string;
}

export interface IFinalQueueAPIResponseModerationTypes {
  book: IAllQueueModerationResponseTypes;
  error_message: string;
  id: number;
  is_preorder: boolean;
  moderation_comments: IFinalModerationCommentTypes[];
  moderator_email: string;
  who_returned_group: 'main_moderator' | 'moderator';
  ebook_status: number;
  updated_at: string;
  nearest_chapter_publication_date: number | null;
  cover: ICover;
  user: IUser;
  force_moderation: boolean;
  is_postmoderation: boolean;
  postmoderation_status: EPostModerationStatus;
  plagiarism: IPlagiarism;
  name: string;
  license_type: ELicenseType;
  processing_time: EProcessingTime;
  status: EBookModerationState;
  is_sent_to_main_moderator_queue: boolean;
  sent_to_moderation: string;
  is_imported: boolean;
}

export interface IFilterSectionTypes {
  name: string;
  checked: boolean;
  label?: JSX.Element | string;
}

export interface IFilterGroupName {
  [id: string]: IFilterSectionTypes;
}

export interface IPrepareSetFilterTypes {
  groupName: string;
  id: string;
  checked: boolean;
}

export interface IAutocompleteFiltersTypes {
  mailModerators: string[];
  [mailProfiles: string]: string[];
}

export interface IFiltersSwitchersTypes {
  postmoderation: boolean;
  plagiary?: 'plagiary';
}

export interface IAutocompletePrepareTypes {
  groupName: string;
  group: string[];
}

export interface IErrorBooksTypes<T> {
  field: T;
  messages: string[];
}

export interface ICountersModerationBooksTypes {
  book_queue: IBookQueueCountTypes;
  moderation_book: IModerationBookCountTypes;
  moderation_book_final: IModerationBookFinalCountTypes;
  moderation_book_final_error: number;
  moderation_book_final_freezed: number;
  profile_queue: IProfileQueueCountTypes;
  moderation_profile: IModerationProfileCountTypes;
}

export interface IBookQueueCountTypes {
  new_book_queue: number;
  old_book_queue: number;
}

export interface IModerationBookCountTypes {
  new_book_moderation: number;
  old_book_moderation: number;
}

export interface IModerationBookFinalCountTypes {
  new_book_final_moderation: number;
  old_book_final_moderation: number;
}

export interface IProfileQueueCountTypes {
  new_profile_queue: number;
  old_profile_queue: number;
}

export interface IModerationProfileCountTypes {
  new_profile_moderation: number;
  old_profile_moderation: number;
}

interface IAllQueueBooksWithBuiltPaginationType
  extends IAPIResponse<IAllQueueModerationResponseTypes[] | null> {
  pagination?: {
    count: number;
    next_page: string;
    previous_page: string;
  };
  result: IAllQueueModerationResponseTypes[] | null;
  type?: EResultType;
}

interface IAllQueueProfileTypesWithBuiltPaginationType
  extends IAPIResponse<IAllQueueProfileTypes[] | null> {
  pagination?: {
    count: number;
    next_page: string;
    previous_page: string;
  };
  result: IAllQueueProfileTypes[] | null;
  type?: EResultType;
}

interface IMyQueueProfilesTypesWithBuiltPaginationType
  extends IAPIResponse<IMyQueueProfilesTypes[] | null> {
  pagination?: {
    count: number;
    next_page: string;
    previous_page: string;
  };
  result: IMyQueueProfilesTypes[] | null;
  type?: EResultType;
}

interface IMyQueueModerationResponseTypesWithBuiltPaginationType
  extends IAPIResponse<IMyQueueModerationResponseTypes[] | null> {
  pagination?: {
    count: number;
    next_page: string;
    previous_page: string;
  };
  result: IMyQueueModerationResponseTypes[] | null;
  type?: EResultType;
}

interface IFinalQueueAPIResponseModerationTypesWithBuiltPaginationType
  extends IAPIResponse<IFinalQueueAPIResponseModerationTypes[] | null> {
  pagination?: {
    count: number;
    next_page: string;
    previous_page: string;
  };
  result: IFinalQueueAPIResponseModerationTypes[] | null;
  type?: EResultType;
}

export interface IAllQueueStateType {
  isFetched: boolean;
  isFetching: boolean;
  isFilterFetching: boolean;
  profiles: IProfileAutocompleteTypes[];
  metaProfiles: IRequestMetaProfilesTypes | null;
  moderators: IModeratorsAutocompleteTypes[];
  metaModerators: IRequestMetaModeratorsTypes | null;
  allQueueBooksReturns: IAllQueueBooksWithBuiltPaginationType;
  allQueueBooksNew: IAllQueueBooksWithBuiltPaginationType;
  allQueueProfileReturns: IAllQueueProfileTypesWithBuiltPaginationType;
  allQueueProfileNew: IAllQueueProfileTypesWithBuiltPaginationType;
  myQueueProfilesReturns: IMyQueueProfilesTypesWithBuiltPaginationType;
  myQueueProfilesNew: IMyQueueProfilesTypesWithBuiltPaginationType;
  myBooksQueueReturns: IMyQueueModerationResponseTypesWithBuiltPaginationType;
  myBooksQueueNew: IMyQueueModerationResponseTypesWithBuiltPaginationType;
  finalQueueBooksReturns: IFinalQueueAPIResponseModerationTypesWithBuiltPaginationType;
  finalQueueBooksNew: IFinalQueueAPIResponseModerationTypesWithBuiltPaginationType;
  finalQueueBooksErrors: IFinalQueueAPIResponseModerationTypesWithBuiltPaginationType;
  finalQueueBooksFreezed: IFinalQueueAPIResponseModerationTypesWithBuiltPaginationType;
  page: string;
  tabName: EModerationBookTabs | EModerationProfileTabs;
  counters: ICountersModerationBooksTypes | null;
  filtersCheckBoxGroup: Record<string, IFilterGroupName>;
  filtersAutocompleteGroup: IAutocompleteFiltersTypes;
  filtersSwitchers: IFiltersSwitchersTypes;
  moderationId: number | null;
  errors: IApiResponseErrorField[] | string[];
}

// Тип договора между нами и автором.
// Нужно модераторам в очередях
export enum EAgreementType {
  offer = 'offer',
  direct = 'direct',
  directTop = 'direct_top',
}
export interface IModerationBookInfo {
  id: number;
  book: {
    id: number;
    ebook_status: number;
    updated_at: string;
    cover: ICover;
    user: {
      id: number;
      short_name: string;
      email: string;
      status: EProfileStatus;
      status_profile_api: string;
      account_royalty_offer_status: EAccountRoyaltyOfferStatus;
      agreement_type: EAgreementType;
      published_books_counter: number;
      published_drafts_counter: number;
    };
    force_moderation: boolean;
    postmoderation_status: EPostModerationStatus;
    plagiarism: IPlagiarism;
    name: string;
    license_type: ELicenseType;
    processing_time: EProcessingTime;
    is_postmoderation: boolean;
    weight: number;
  };
  is_preorder: boolean;
  status: EBookModerationState;
  nearest_chapter_publication_date: number | null;
  moderation_comments: {
    moderation_book_id: number;
    comment_template: {
      id: number;
      title: string;
      tab: ETabType;
      comment: string;
    };
    comment: string;
  }[];
  moderator_email: string | null;
  who_returned_group: string | null;
  error_message: string;
  is_sent_to_main_moderator_queue: boolean;
  is_imported: boolean;
}

export interface IUpdateBookModerationStateRequest {
  is_preorder?: boolean;
  status: EBookModerationState;
  moderation_comments?: IModerationComment[];
}

export interface IUpdateBookModerationStateResponse {
  ebook_status: EBookModerationState;
  cover: ICover;
  user: IUser;
  force_moderation: boolean;
  postmoderation_status: string;
  plagiarism: IPlagiarism;
  name: string;
  is_preorder: boolean;
  status: EBookModerationState;
  moderation_comments: IModerationComment[];
}
