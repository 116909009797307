import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendYandexAnalytics } from 'analytics/sendAnalytics';

import { doFetch } from 'utils/doFetch';

export interface ISendCommentReplyThunkProps {
  review_litres_id: number;
  reply_text: string;
  employee_id: number;
  artId: number;
  userId: number;
  userUuid: string;
}

export const sendCommentReplyThunk = createAsyncThunk(
  'bookMeta/sendCommentReplyThunk',
  async ({
    review_litres_id,
    reply_text,
    employee_id,
    artId,
    userId,
    userUuid,
  }: ISendCommentReplyThunkProps) => {
    const apiPointName = 'sendCommentReply';

    const result = await doFetch<typeof apiPointName>({
      apiPointName,
      body: {
        review_litres_id,
        reply_text,
        employee_id,
      },
    });

    if (!result.errors.length) {
      sendYandexAnalytics({
        action: 'reply_comment_success',
        goalParams: {
          art_id: Number(artId),
          selfpub_user_id: userId,
          user_id: userUuid,
        },
      });
    }

    return result;
  },
);
