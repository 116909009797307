export enum ESeriesState {
  in_progress = 'in_progress',
  published = 'published',
}

export enum ESeriesStatus {
  not_published = 'not_published',
  unpublished = 'unpublished',
  queued_for_upload = 'queued_for_upload',
  in_progress = 'in_progress',
  published = 'published',
  draft = 'draft',
  uploaded = 'uploaded',
  remote_check = 'remote_check',
  api_error = 'api_error',
  remote_published = 'remote_published',
  remote_unpublished = 'remote_unpublished',
}

export const SERIES_STATUS_TEXT = {
  [ESeriesStatus.queued_for_upload]: 'Ожидает публикации',
  [ESeriesStatus.unpublished]: 'Снято с публикации',
  [ESeriesStatus.in_progress]: 'Ожидает публикации',
  [ESeriesStatus.published]: 'Опубликовано',
  [ESeriesStatus.draft]: 'Подготовка к публикации',
  [ESeriesStatus.uploaded]: 'Ожидает публикации',
  [ESeriesStatus.remote_check]: 'Ожидает публикации',
  [ESeriesStatus.api_error]: 'Ожидает публикации',
  [ESeriesStatus.not_published]: 'Неопубликовано',
  [ESeriesStatus.remote_published]: 'Опубликовано',
  [ESeriesStatus.remote_unpublished]: 'Снято с публикации',
};

export const SeriesNameMaxLength = 100;

export const SERIES_LIMIT = 10;
export const SERIES_ART_SEARCH_LIMIT = 10;
