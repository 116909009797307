import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext, NextPageContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface ICirculationCoverGenerationThunkProps {
  context?: GetServerSidePropsContext | NextPageContext;
  bookId: number;
}

export const getCirculationCoverGenerationThunk = createAsyncThunk(
  'circulation/cover/generation',
  async ({ context, bookId }: ICirculationCoverGenerationThunkProps) => {
    const apiPointName = 'getCirculationCoverGeneration';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: {
        bookId,
      },
    });
  },
);
