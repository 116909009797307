import { pinoLogger } from './logger';

const fetchLogger = pinoLogger.child({
  context: 'fetch',
});

export const logFetchInfo = (
  response: Response,
  method?: string,
  pageUrl?: string,
): void => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  const logData = {
    status: Number(response.status),
    method: method || '',
    url: response.url || '',
    ssrPageUrl: pageUrl || '',
  };

  // логгер только для SSR (graylog)
  if (typeof window === 'undefined') {
    fetchLogger.info(logData);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logFetchError = (error: any, pageUrl?: string): void => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  const logData = {
    error,
    ssrPageUrl: pageUrl || '',
  };

  // логгер только для SSR (graylog)
  if (typeof window === 'undefined') {
    fetchLogger.error(logData);
  }
};
