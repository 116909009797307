import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

interface ISendCirculationOrderThunk {
  orderId: string;
  context?: GetServerSidePropsContext;
}

export const sendCirculationOrderThunk = createAsyncThunk(
  'orders/orderCirculation/sendCirculationOrder',
  async ({ orderId, context }: ISendCirculationOrderThunk) => {
    const apiPointName = 'sendCirculationOrder';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: { orderId },
      context,
    });
  },
);
