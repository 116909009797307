import { createSlice } from '@reduxjs/toolkit';
import { ECirculationDeliveryType } from 'entities/Circulation/constants';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getCirculationCostThunk } from 'sp-redux/thunks/circulation/getCirculationCostThunk';
import { getCirculationCoverGenerationThunk } from 'sp-redux/thunks/circulation/getCirculationCoverGenerationThunk';
import { getCirculationPdfGenerationThunk } from 'sp-redux/thunks/circulation/getCirculationPdfGenerationThunk';

import type { ICirculationState } from './types';

const initialState: ICirculationState = {
  isCostFetching: true,
  isCoverFetching: true,
  isPdfFetching: true,
  cost: {
    delivery_cost: '',
    print_cost: '',
    discount_amount: '',
    total_amount: '',
    weight: '',
    delivery_type: ECirculationDeliveryType.postOffice,
    typography: '',
    promocode_status: '',
  },
  cover: {
    ebook_image_url: '',
    printed_image_rgb_url: '',
    printed_image_rgb_hard_url: '',
    status: '',
    processing_status: '',
  },
  pdf: {
    url: '',
    page_count: 0,
    status: '',
    processing_status: '',
  },
  costErrors: [],
};

const circulationSlice = createSlice({
  name: 'circulation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCirculationCostThunk.pending, state => {
      state.isCostFetching = true;
    });

    builder.addCase(getCirculationCostThunk.fulfilled, (state, action) => {
      state.isCostFetching = false;
      // eslint-disable-next-line
      if (action.payload && Object.keys(action.payload.result).length > 0) {
        state.cost = action.payload.result;
      }
      state.costErrors = action.payload.errors;
    });

    builder.addCase(getCirculationCoverGenerationThunk.pending, state => {
      state.isCoverFetching = true;
    });

    builder.addCase(
      getCirculationCoverGenerationThunk.fulfilled,
      (state, action) => {
        state.isCoverFetching = false;

        // eslint-disable-next-line
        if (action.payload && Object.keys(action.payload.result).length > 0) {
          state.cover = action.payload.result;
        }
      },
    );

    builder.addCase(getCirculationPdfGenerationThunk.pending, state => {
      state.isPdfFetching = true;
    });

    builder.addCase(
      getCirculationPdfGenerationThunk.fulfilled,
      (state, action) => {
        state.isPdfFetching = false;

        // eslint-disable-next-line
        if (action.payload && Object.keys(action.payload.result).length > 0) {
          state.pdf = action.payload.result;
        }
      },
    );

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<ICirculationState>) => {
        return action.payload.circulation;
      },
    );
  },
});

export const circulationReducer = circulationSlice.reducer;
