import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getAllNotificationsThunk } from 'sp-redux/thunks/notifications/getAllNotificationsThunk';
import { getNotificationsCountThunk } from 'sp-redux/thunks/notifications/getNotificationsCountThunk';
import { getTypeNotificationsThunk } from 'sp-redux/thunks/notifications/getTypeNotificationsThunk';
import { patchNotificationReadThunk } from 'sp-redux/thunks/notifications/patchNotificationReadThunk';

import type { INotificationsState, TNotificationsType } from './types';

const initialState: INotificationsState = {
  isFetched: false,
  isFetching: true,
  counters: { total: 0, books: 0, promo: 0, news: 0 },
  notifications: { books: [], promo: [], news: [], total: [] },
  pagination: {
    books: { count: 0, next_page: '', previous_page: '' },
    promo: { count: 0, next_page: '', previous_page: '' },
    news: { count: 0, next_page: '', previous_page: '' },
    total: { count: 0, next_page: '', previous_page: '' },
  },
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getNotificationsCountThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getNotificationsCountThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;
      // eslint-disable-next-line
      if (action.payload && Object.keys(action.payload.result).length > 0) {
        const { total, books, promo, news } = action.payload.result;

        state.counters = {
          total,
          books,
          promo,
          news,
        };
      }
    });

    builder.addCase(getTypeNotificationsThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getAllNotificationsThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getTypeNotificationsThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      const notificationType = action.meta.arg.type;
      // eslint-disable-next-line
      if (action.payload && Object.keys(action.payload.result).length > 0) {
        state.notifications[notificationType] = [
          ...state.notifications[notificationType],
          ...action.payload.result,
        ];
      }

      if (action.payload.pagination) {
        state.pagination[notificationType] = action.payload.pagination;
      }
    });

    builder.addCase(getAllNotificationsThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;
      // eslint-disable-next-line
      if (action.payload && Object.keys(action.payload.result).length > 0) {
        state.notifications.total = [
          ...state.notifications.total,
          ...action.payload.result,
        ];
      }
    });

    builder.addCase(patchNotificationReadThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(patchNotificationReadThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      const notificationId = action.payload.result.id;
      const type = action.meta.arg.type as TNotificationsType;

      state.notifications[type] = state.notifications[type].map(notification =>
        notification.id === notificationId
          ? action.payload.result
          : notification,
      );
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<INotificationsState>) => {
        return action.payload.notificationsCenter;
      },
    );
  },
});

export const notificationsReducer = notificationsSlice.reducer;
