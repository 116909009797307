import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getBookModerationInfo = createAsyncThunk(
  'moderation/getModerationInfo',
  async ({
    moderationId,
    context,
  }: {
    moderationId: string;
    context?: GetServerSidePropsContext;
  }) => {
    const apiPointName = 'getModerationInfo';

    return await doFetch<typeof apiPointName>({
      apiPointName: apiPointName,
      urlParams: { moderationId },
      context,
    });
  },
);
