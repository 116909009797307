import { createSlice } from '@reduxjs/toolkit';
import { IApiResponseErrorField } from 'app.types';
import { IPerson } from 'modules/persons/types';

import { getPersonsThunk } from 'sp-redux/thunks/persons/getPersonsThunk';

export interface IPersonsState {
  persons: IPerson[];
  isFetching: boolean;
  isFetched: boolean;
  errors: IApiResponseErrorField[] | null;
}

export const initialState: IPersonsState = {
  persons: [],
  isFetching: false,
  isFetched: false,
  errors: null,
};

const personsSlice = createSlice({
  name: 'persons',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPersonsThunk.pending, state => {
      state.isFetching = true;
      state.errors = null;
    });
    builder.addCase(getPersonsThunk.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isFetched = true;

      state.errors = action.payload.errors;

      if (state.errors.length === 0) {
        state.persons = action.payload.result;
      }
    });
  },
});

export const personsReducer = personsSlice.reducer;
