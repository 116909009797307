import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchArtReviews } from 'modules/comments/helpers/fetchArtReviews';
import { GetServerSidePropsContext } from 'next';

import { externalServicesUrls } from 'constants/externalServicesUrls';

const requestLimit = 10;

interface IGetBookReviewsThunkProps {
  litresArtId: number;
  after?: string;
  context?: GetServerSidePropsContext;
}

export const getBookReviewsThunk = createAsyncThunk(
  'bookMeta/getBookReviews',
  async ({ litresArtId, after, context }: IGetBookReviewsThunkProps) => {
    return await fetchArtReviews({
      prodUrl: externalServicesUrls.artComments.production(litresArtId),
      stageUrl: externalServicesUrls.artComments.master(litresArtId),
      o: 'new',
      limit: requestLimit,
      after,
      context,
    });
  },
);
