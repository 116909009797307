import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getModeratorsThunk = createAsyncThunk(
  'moderation/getModerators',
  async ({
    context,
    term,
    page,
  }: {
    context?: GetServerSidePropsContext;
    term?: string;
    page?: number | string;
  }) => {
    const apiPointName = 'moderatorsAutocomplete';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      queryParams: {
        term,
        page,
      },
    });
  },
);
