import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux/index';
import { getOfferBaseInfoThunk } from 'sp-redux/thunks/offer/getOfferBaseInfo';

import type { IOfferBaseInfoStateType } from './types';

const initialState = {
  isFetched: false,
  isFetching: true,
  baseInfo: [],
} as IOfferBaseInfoStateType;

const OfferSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getOfferBaseInfoThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;
      state.baseInfo = action.payload.result;
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IOfferBaseInfoStateType>) => {
        return action.payload.offer;
      },
    );
  },
});

export const offerReducer = OfferSlice.reducer;
