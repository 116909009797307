import * as Sentry from '@sentry/browser';

import {
  ID_YANDEX_METRIKA,
  YANDEX_METRIKA_AB_COOKIE_NAME,
  YANDEX_USERSPLIT_URL,
} from './const';

interface IUaasResponse {
  n: string;
  v: string;
  t: EFlagType;
}

interface IResponseAnswer {
  flags: Record<string, string[] | undefined>;
  i?: string;
  experiments?: string;
  ready: true;
}

enum EFlagType {
  Flag = 'flag',
  Visual = 'visual',
  Redirect = 'redir',
}

/**
 * Возвращает массив, с данными в формате
 * name=value, чтобы записать их в куки.
 */
export const getAbData = async (
  url?: string,
  ymabCookie?: string,
): Promise<string[]> => {
  let requestUrl = `${YANDEX_USERSPLIT_URL}?client_id=metrika.${ID_YANDEX_METRIKA}`;
  if (url) {
    requestUrl = `${requestUrl}&url=${url}`;
  }
  if (ymabCookie) {
    requestUrl = `${requestUrl}&i=${ymabCookie}`;
  }
  const response = await fetch(requestUrl)
    .then(res => res.json() as Promise<IResponseAnswer>)
    .catch((e: unknown) => {
      Sentry.captureException('Failed to fetch AB data', {
        extra: { error: e },
      });
      return undefined;
    });

  if (response) {
    const responseFlags = response.flags;
    const result = response.i
      ? [`${YANDEX_METRIKA_AB_COOKIE_NAME}=${response.i}`]
      : [];
    if (Array.isArray(responseFlags)) {
      return [...result, ...transformFlagsToCookieArray(responseFlags)];
    } else {
      return result;
    }
  } else {
    return [];
  }
};

export const transformFlagsToCookieArray = (
  flags: IUaasResponse[],
): string[] => {
  const result = flags
    .filter(item => item.t === EFlagType.Flag)
    .map(flag => `${flag.n}=${flag.v}`);
  return result;
};
