import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getEmployeesThunk = createAsyncThunk(
  'employees/getEmployees',
  async (context?: GetServerSidePropsContext) => {
    const apiPointName = 'getEmployees';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
    });
  },
);
