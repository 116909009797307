import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetPaymentOrderInfoThunk {
  hash: string;
  context?: GetServerSidePropsContext;
}

export const getPaymentOrderInfoThunk = createAsyncThunk(
  'services/paymentOrderInfo',
  async ({ hash, context }: IGetPaymentOrderInfoThunk) => {
    const apiPointName = 'getPaymentOrderInfo';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { hash },
    });
  },
);
