import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext, NextPageContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IPromotionsOrdersThunkProps {
  context?: GetServerSidePropsContext | NextPageContext;
  status?: string;
}

export const getPromotionsOrdersThunk = createAsyncThunk(
  'orders/promo',
  async ({ context, status }: IPromotionsOrdersThunkProps) => {
    const apiPointName = 'getPromotionsOrders';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      queryParams: {
        status,
      },
    });
  },
);
