import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

interface IGetAccountTaxDataByUserIdThunkProps {
  context?: GetServerSidePropsContext;
  userId: number;
}

export const getAccountTaxDataByUserIdThunk = createAsyncThunk(
  'account/getAccountTaxDataByUserId',
  async (props: IGetAccountTaxDataByUserIdThunkProps) => {
    const apiPointName = 'getAccountTaxDataByUserId';

    const { context, userId } = props;

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: {
        userId: userId,
      },
      context,
    });
  },
);
