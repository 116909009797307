import type { IApiResponseErrorField } from 'app.types';
import createDebug from 'debug';
import { GetServerSidePropsContext } from 'next';
import queryString from 'query-string';

import { isTestEnvironment } from 'utils/environment';
import { fetchWrapper } from 'utils/fetchWrapper';

import type { IReview } from '../components/Review/types';

const debug = createDebug('Foundation:fetch');

interface IFetchArtReviewRequest {
  prodUrl: string;
  stageUrl: string;
  o?: 'new' | 'popular';
  limit?: number;
  after?: string | string[] | null;
  with_review_id?: string;
  with_reply_id?: string;
  before?: string;
  context?: GetServerSidePropsContext;
}

export interface IFetchArtReviewsResponse {
  status: number;
  errors: IApiResponseErrorField[];
  payload: {
    pagination: {
      next_page: string | null;
      previous_page: string | null;
    };
    data: IReview[];
  };
  newReview: IReview[];
}

export const fetchArtReviews = async ({
  prodUrl,
  stageUrl,
  o,
  limit,
  after,
  with_review_id,
  with_reply_id,
  before,
  context,
}: IFetchArtReviewRequest): Promise<IFetchArtReviewsResponse> => {
  const fetchUrl = isTestEnvironment() ? stageUrl : prodUrl;

  const options: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
    credentials: 'include',
  };

  const queryParams = {
    o,
    limit,
    before,
    after,
    with_review_id,
    with_reply_id,
  };

  const path = `${fetchUrl}?${queryString.stringify(queryParams)}`;

  const response = await fetchWrapper(path, options, context);

  debug(`[${response.status}] ${path}`);
  return await response.json();
};
