import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

interface IGetServicePromocode {
  serviceTypeId: string;
  promocode: string;
  context?: GetServerSidePropsContext;
}

export const getServicePromocode = createAsyncThunk(
  'services/getServicePromocode',
  async ({ serviceTypeId, promocode, context }: IGetServicePromocode) => {
    const apiPointName = 'getServicePromocode';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: { service_type_id: serviceTypeId, promo_code: promocode },
      context,
    });
  },
);
