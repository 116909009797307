import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getPublisherGeneralStatisticsThunk } from 'sp-redux/thunks/publisher/statistics/getPublisherGeneralStatisticsThunk';
import { getPublisherReadingStatisticsThunk } from 'sp-redux/thunks/publisher/statistics/getPublisherReadingStatisticsThunk';
import { getPublisherReadingStatisticsV2Thunk } from 'sp-redux/thunks/publisher/statistics/getPublisherReadingStatisticsThunkV2';
import { getPublisherSalesStatisticsThunk } from 'sp-redux/thunks/publisher/statistics/getPublisherSalesStatisticsThunk';
import { getPublisherSalesStatisticsV2Thunk } from 'sp-redux/thunks/publisher/statistics/getPublisherSalesStatisticsThunkV2';
import { getPublisherTotalStatisticsThunk } from 'sp-redux/thunks/publisher/statistics/getPublisherTotalStatisticsThunk';

import { IPublisherStatisticsState } from './types';

const initialState: IPublisherStatisticsState = {
  reading: {
    isFetching: false,
    data: {
      data: [],
      total: {
        read_count_pages: 0,
        read_royalty_sum: 0,
        prorata_count_pages: 0,
        prorata_royalty_sum: 0,
        items: 0,
        total_royalty_sum: 0,
      },
    },
    errors: [],
  },
  readingV2: {
    isFetching: false,
    data: {
      data: [],
      total: {
        rows: 0,
      },
    },
    errors: [],
  },
  sales: {
    isFetching: false,
    data: {
      data: [],
      total: {
        items: 0,
        sale_count: 0,
        sale_royalty_sum: 0,
      },
    },
    errors: [],
  },
  salesV2: {
    isFetching: false,
    data: {
      data: [],
      total: {
        rows: 0,
      },
    },
    errors: [],
  },
  general: {
    isFetching: false,
    data: {
      data: [],
      total: {
        royalty_sum: 0,
        royalty_sum_total: 0,
      },
    },
    errors: [],
  },
  total: {
    isFetching: false,
    data: {
      royalty_sum: 0,
    },
    errors: [],
  },
};

const publisherStatisticsSlice = createSlice({
  name: 'publisherStatistics',
  initialState,
  reducers: {
    resetReadingData: state => {
      state.reading = initialState.reading;
    },
    resetSalesData: state => {
      state.sales = initialState.sales;
    },
    resetReadingDataV2: state => {
      state.readingV2 = initialState.readingV2;
    },
    resetSalesDataV2: state => {
      state.salesV2 = initialState.salesV2;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPublisherGeneralStatisticsThunk.pending, state => {
      state.general.isFetching = true;
    });
    builder.addCase(getPublisherGeneralStatisticsThunk.rejected, state => {
      state.general.isFetching = false;
    });
    builder.addCase(
      getPublisherGeneralStatisticsThunk.fulfilled,
      (state, action) => {
        state.general.isFetching = false;
        state.general.data = action.payload.result;
        state.general.errors = action.payload.errors;
      },
    );
    builder.addCase(getPublisherReadingStatisticsThunk.pending, state => {
      state.reading.isFetching = true;
    });
    builder.addCase(getPublisherReadingStatisticsThunk.rejected, state => {
      state.reading.isFetching = false;
    });
    builder.addCase(getPublisherReadingStatisticsV2Thunk.pending, state => {
      state.readingV2.isFetching = true;
    });
    builder.addCase(getPublisherReadingStatisticsV2Thunk.rejected, state => {
      state.readingV2.isFetching = false;
    });
    builder.addCase(
      getPublisherReadingStatisticsThunk.fulfilled,
      (state, action) => {
        state.reading.isFetching = false;
        const currentData = state.reading.data?.data || [];
        state.reading.errors = action.payload.errors;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const resultData = action.payload.result.data || [];
        const updatedData = [...currentData, ...resultData];
        if (state.reading.data?.data) {
          state.reading.data.data = updatedData;
          state.reading.data.total.items = action.payload.result.total.items;
          state.reading.data.total.read_count_pages =
            action.payload.result.total.read_count_pages;
          state.reading.data.total.read_royalty_sum =
            action.payload.result.total.read_royalty_sum;
          state.reading.data.total.prorata_count_pages =
            action.payload.result.total.prorata_count_pages;
          state.reading.data.total.prorata_royalty_sum =
            action.payload.result.total.prorata_royalty_sum;
          state.reading.data.total.total_royalty_sum =
            action.payload.result.total.total_royalty_sum;
        }
      },
    );
    builder.addCase(
      getPublisherReadingStatisticsV2Thunk.fulfilled,
      (state, action) => {
        state.readingV2.isFetching = false;
        const currentData = state.readingV2.data?.data || [];
        state.readingV2.errors = action.payload.errors;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const resultData = action.payload.result.data || [];
        const updatedData = [...currentData, ...resultData];
        if (state.readingV2.data?.data) {
          state.readingV2.data.data = updatedData;
          state.readingV2.data.total.rows = action.payload.result.total.rows;
        }
      },
    );
    builder.addCase(getPublisherSalesStatisticsThunk.pending, state => {
      state.sales.isFetching = true;
    });
    builder.addCase(getPublisherSalesStatisticsThunk.rejected, state => {
      state.sales.isFetching = false;
    });
    builder.addCase(getPublisherSalesStatisticsV2Thunk.pending, state => {
      state.salesV2.isFetching = true;
    });
    builder.addCase(getPublisherSalesStatisticsV2Thunk.rejected, state => {
      state.salesV2.isFetching = false;
    });
    builder.addCase(
      getPublisherSalesStatisticsThunk.fulfilled,
      (state, action) => {
        state.sales.isFetching = false;
        state.sales.errors = action.payload.errors;
        const currentData = state.sales.data?.data || [];
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const resultData = action.payload.result.data || [];
        const resultTotal = action.payload.result.total;
        const updatedData = [...currentData, ...resultData];
        if (state.sales.data?.data) {
          state.sales.data.data = updatedData;
          state.sales.data.total.items = resultTotal.items;
          state.sales.data.total.sale_count = resultTotal.sale_count;
          state.sales.data.total.sale_royalty_sum =
            resultTotal.sale_royalty_sum;
        }
      },
    );
    builder.addCase(
      getPublisherSalesStatisticsV2Thunk.fulfilled,
      (state, action) => {
        state.salesV2.isFetching = false;
        state.salesV2.errors = action.payload.errors;
        const currentData = state.salesV2.data?.data || [];
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const resultData = action.payload.result.data || [];
        const resultTotal = action.payload.result.total;
        const updatedData = [...currentData, ...resultData];
        if (state.salesV2.data?.data) {
          state.salesV2.data.data = updatedData;
          state.salesV2.data.total.rows = resultTotal.rows;
        }
      },
    );
    builder.addCase(getPublisherTotalStatisticsThunk.pending, state => {
      state.general.isFetching = true;
    });
    builder.addCase(getPublisherTotalStatisticsThunk.rejected, state => {
      state.general.isFetching = false;
    });
    builder.addCase(
      getPublisherTotalStatisticsThunk.fulfilled,
      (state, action) => {
        state.general.isFetching = false;
        state.total.errors = action.payload.errors;
        if (state.total.data) {
          state.total.data.royalty_sum = action.payload.result.royalty_sum;
        }
      },
    );
    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IPublisherStatisticsState>) => {
        return action.payload.publisherStatistics;
      },
    );
  },
});

export const {
  resetReadingData,
  resetSalesData,
  resetSalesDataV2,
  resetReadingDataV2,
} = publisherStatisticsSlice.actions;

export const publisherStatisticsReducer = publisherStatisticsSlice.reducer;
