import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IPatchSeriesDeatils {
  seriesId: string | number;
  artList: (string | number)[];
  context?: GetServerSidePropsContext;
  name: string;
}

export const patchSeriesDeatilsThunk = createAsyncThunk(
  'series/patchSeriesDeatils',
  async ({ seriesId, artList, name, context }: IPatchSeriesDeatils) => {
    const apiPointName = 'patchSeriesDeatils';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: {
        seriesId,
      },
      body: {
        art_list: artList,
        name: name,
      },
    });
  },
);
