import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import type { TNotificationsType } from 'sp-redux/slices/notifications/types';

import { doFetch } from 'utils/doFetch';

export interface ITypeNotificationsThunkProps {
  type: TNotificationsType;
  limit: number;
  offset: number;
  context?: GetServerSidePropsContext;
}

export const getTypeNotificationsThunk = createAsyncThunk(
  'getNotificationsByType',
  async ({ context, limit, offset, type }: ITypeNotificationsThunkProps) => {
    const apiPointName = 'getNotificationsByType';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      queryParams: {
        type,
        limit,
        offset,
      },
    });
  },
);
