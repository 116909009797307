import { configureStore } from '@reduxjs/toolkit';
import type { ThunkAction } from '@reduxjs/toolkit';
import type { IPublicRuntimeConfig } from 'app.types';
import { createWrapper } from 'next-redux-wrapper';
import getConfig from 'next/config';
import type { Action } from 'redux';
import logger from 'redux-logger';

import { audioBookMetaReducer } from 'sp-redux/slices/audioBookMeta';
import { bookMetaReducer } from 'sp-redux/slices/bookMeta';
import { BookPriceReducer } from 'sp-redux/slices/bookPrice';
import { commentTemplatesReducer } from 'sp-redux/slices/commentTemplates';
import { documentReducer } from 'sp-redux/slices/document';
import { employeesReducer } from 'sp-redux/slices/employees';
import { moderationReducer } from 'sp-redux/slices/moderator';
import { myAudioBooksReducer } from 'sp-redux/slices/myAudioBooks';
import { myBooksReducer } from 'sp-redux/slices/myBooks';
import { notificationsReducer } from 'sp-redux/slices/notifications';
import { offerReducer } from 'sp-redux/slices/offer';
import { ownersReducer } from 'sp-redux/slices/owners';
import { paymentsReducer } from 'sp-redux/slices/payments';
import { profileReducer } from 'sp-redux/slices/profile';
import { publisherStatisticsReducer } from 'sp-redux/slices/publisher/statistics';
import { seriesReducer } from 'sp-redux/slices/series';
import { seriesDetailsReducer } from 'sp-redux/slices/seriesDetails';
import { statisticsReducer } from 'sp-redux/slices/statistics';
import { uiReducer } from 'sp-redux/slices/ui';
import { userInfoReducer } from 'sp-redux/slices/userInfo';
import { userRegistrationReducer } from 'sp-redux/slices/userRegistration';
import { verificationProfileReducer } from 'sp-redux/slices/verificationProfile';

import { accountReducer } from './slices/account';
import { circulationReducer } from './slices/circulation';
import { genresReducer } from './slices/genres';
import { ordersReducer } from './slices/orders';
import { personsReducer } from './slices/persons';
import { podcastMetaReducer } from './slices/podcastMeta';
import { servicesReducer } from './slices/services';

export const reducer = {
  userInfo: userInfoReducer,
  userRegistration: userRegistrationReducer,
  myBooks: myBooksReducer,
  myAudioBooks: myAudioBooksReducer,
  bookMeta: bookMetaReducer,
  audioBookMeta: audioBookMetaReducer,
  ui: uiReducer,
  profile: profileReducer,
  document: documentReducer,
  offer: offerReducer,
  employees: employeesReducer,
  moderation: moderationReducer,
  commentTemplates: commentTemplatesReducer,
  bookPrice: BookPriceReducer,
  owners: ownersReducer,
  verificationProfile: verificationProfileReducer,
  notificationsCenter: notificationsReducer,
  statistics: statisticsReducer,
  series: seriesReducer,
  seriesDetails: seriesDetailsReducer,
  services: servicesReducer,
  podcastMeta: podcastMetaReducer,
  genres: genresReducer,
  orders: ordersReducer,
  account: accountReducer,
  payments: paymentsReducer,
  circulation: circulationReducer,
  publisherStatistics: publisherStatisticsReducer,
  persons: personsReducer,
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const makeStore = () => {
  const { publicRuntimeConfig } = getConfig();
  const { app }: IPublicRuntimeConfig = publicRuntimeConfig;

  return configureStore({
    reducer,
    middleware: getDefaultMiddleware => {
      const middlewares = getDefaultMiddleware({
        serializableCheck: {
          ignoredPaths: [
            'document.document.uploadedDocument',
            'document.document.uploadedPhotoWithDocument',
            'document.document.uploadedAdditionalScan',
          ],

          ignoredActions: [
            'document/uploadPhotoWithDocument/fulfilled',
            'document/uploadDocumentScan/fulfilled',
            'document/uploadAdditionalScan/fulfilled',
          ],
        },
      });

      if (app.env.isDev) {
        middlewares.concat(logger);
      }

      return middlewares;
    },
    devTools: true,
  });
};

export type TAppStore = ReturnType<typeof makeStore>;
export type TAppState = ReturnType<TAppStore['getState']>;
export type TAppThunk<ReturnType> = ThunkAction<
  ReturnType,
  TAppState,
  unknown,
  Action
>;

export interface IHydrateAction<T> extends Action {
  payload: {
    [stateName: string]: T;
  };
}

export const wrapper = createWrapper<TAppStore>(makeStore, {
  debug: false,
});
