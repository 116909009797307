import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getBookCommentsForUser = createAsyncThunk(
  'bookMeta/getBookCommentsForUser',
  async ({
    bookId,
    context,
  }: {
    bookId: string;
    context?: GetServerSidePropsContext;
  }) => {
    const apiPointName = 'getBookCommentsForUser';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { bookId },
    });
  },
);

export const getBookCommentsThunkForModerator = createAsyncThunk(
  'bookMeta/getBookCommentsThunkForModerator',
  async ({
    bookId,
    context,
  }: {
    bookId: string;
    context?: GetServerSidePropsContext;
  }) => {
    const apiPointName = 'getBookCommentsForModerator';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { bookId },
    });
  },
);
