import pino from 'pino';

const loggerOptions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mixin(): any {
    return {
      timestamp: pino.stdTimeFunctions.isoTime,
      instance: {
        host: process.env.NEXT_PUBLIC_HOST,
        api: process.env.NEXT_PUBLIC_API_URL,
      },
    };
  },
  formatters: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    level: (label: any): any => {
      return { level: label };
    },
  },
};

export const pinoLogger = pino(loggerOptions);
