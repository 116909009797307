import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetOrderInfoByOrderId {
  orderId: string;
  context?: GetServerSidePropsContext;
}

export const getServiceInfoByOrderIdThunk = createAsyncThunk(
  'orders/serviceOrder',
  async ({ orderId, context }: IGetOrderInfoByOrderId) => {
    const apiPointName = 'getServiceInfoByOrderId';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { orderId },
    });
  },
);
