import { createAsyncThunk } from '@reduxjs/toolkit';

import { doFetch } from 'utils/doFetch';

export const createEmployeeForBookThunk = createAsyncThunk(
  'bookMeta/createEmployeeForBookThunk',
  async ({
    first_name,
    middle_name,
    last_name,
  }: {
    first_name: string;
    last_name: string;
    middle_name?: string;
  }) => {
    const apiPointName = 'sendEmployee';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      body: {
        first_name,
        middle_name,
        last_name,
      },
    });
  },
);
