import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext, NextPageContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getNotificationsCountThunk = createAsyncThunk(
  'notifications/count',
  async (context?: GetServerSidePropsContext | NextPageContext) => {
    const apiPointName = 'notificationsCount';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
    });
  },
);
