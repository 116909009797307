/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getMyAudioBooksThunk } from 'sp-redux/thunks/myAudioBooks/getMyAudioBooks';

import type { IMyAudioBooks } from './types';

const initialState: IMyAudioBooks = {
  isFetched: false,
  isFetching: true,
  pagination: {},
  myAudioBooks: [],
};

const myAudioBooksSlice = createSlice({
  name: 'myAudioBooks',
  initialState,
  reducers: {
    clearMyAudioBooksList: state => {
      state.myAudioBooks = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(getMyAudioBooksThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      if (Object.keys(action.payload.result).length > 0) {
        state.myAudioBooks = [...state.myAudioBooks, ...action.payload.result];
      }
      if (action.payload.pagination) {
        state.pagination = action.payload.pagination;
      }
    });

    builder.addCase(HYDRATE, (state, action: IHydrateAction<IMyAudioBooks>) => {
      return action.payload.myAudioBooks;
    });
  },
});

export const { clearMyAudioBooksList } = myAudioBooksSlice.actions;

export const myAudioBooksReducer = myAudioBooksSlice.reducer;
