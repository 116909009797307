import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext, NextPageContext } from 'next';

import type { TAppState } from 'sp-redux';

import { doFetch } from 'utils/doFetch';

export const getProfileThunk = createAsyncThunk(
  'profile/getProfile',
  async (context: GetServerSidePropsContext | NextPageContext, thunkAPI) => {
    const apiPointName = 'getProfile';
    const state = thunkAPI.getState() as TAppState;

    if (!state.userInfo.isLogged || !state.userInfo.profile.id) {
      return;
    }

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: {
        profileId: Number(state.userInfo.profile.id),
      },
      context,
    });
  },
);
