import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getMyAudioBooksThunk } from 'sp-redux/thunks/myBooks/getMyAudioBooksThunk';
import { getMyBooksThunk } from 'sp-redux/thunks/myBooks/getMyBooks';
import { getMyBooksCount } from 'sp-redux/thunks/myBooks/getMyBooksCount';
import { getMyDigitalBooksThunk } from 'sp-redux/thunks/myBooks/getMyDigitalBooksThunk';
import { getMyDraftBooksThunk } from 'sp-redux/thunks/myBooks/getMyDraftBooksThunk';
import { getMyPodBooksThunk } from 'sp-redux/thunks/myBooks/getMyPoDBooksThunk';
import { getMyPodcastsThunk } from 'sp-redux/thunks/myBooks/getMyPodcastsThunk';
import { removeBookThunk } from 'sp-redux/thunks/myBooks/removeBook';

import type { IMyBooksStateType } from './types';

const initialState: IMyBooksStateType = {
  isFetched: false,
  isFetching: true,
  myBooks: [],
  digitalBooks: null,
  draftBooks: null,
  audioBooks: null,
  podBooks: null,
  podcasts: null,
  oneBook: {},
  myBookCount: {},
};

const myBooksSlice = createSlice({
  name: 'myBooks',
  initialState,
  reducers: {
    clearMyBooksList: state => {
      state.myBooks = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(getMyBooksThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      if (Object.keys(action.payload.result).length > 0) {
        state.myBooks = [...state.myBooks, ...action.payload.result];
      }
      if (action.payload.pagination) {
        state.pagination = action.payload.pagination;
      }
    });

    builder.addCase(getMyBooksThunk.pending, state => {
      state.isFetching = true;
    });
    builder.addCase(getMyDigitalBooksThunk.pending, state => {
      state.isFetching = true;
    });
    builder.addCase(getMyAudioBooksThunk.pending, state => {
      state.isFetching = true;
    });
    builder.addCase(getMyDraftBooksThunk.pending, state => {
      state.isFetching = true;
    });
    builder.addCase(getMyPodBooksThunk.pending, state => {
      state.isFetching = true;
    });
    builder.addCase(getMyPodcastsThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getMyDigitalBooksThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      state.digitalBooks = action.payload.result;
    });

    builder.addCase(getMyAudioBooksThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      state.audioBooks = action.payload.result;
    });

    builder.addCase(getMyDraftBooksThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      state.draftBooks = action.payload.result;
    });

    builder.addCase(getMyPodBooksThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      state.podBooks = action.payload.result;
    });
    builder.addCase(getMyPodcastsThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      state.podcasts = action.payload.result;
    });

    builder.addCase(removeBookThunk.fulfilled, (state, action) => {
      state.myBooks = state.myBooks.filter(
        item => item.id !== action.payload.bookId,
      );
    });

    builder.addCase(getMyBooksCount.pending, state => {
      state.isFetched = true;
      state.isFetching = false;
    });

    builder.addCase(getMyBooksCount.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      state.myBookCount = action.payload.result;
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IMyBooksStateType>) => {
        return action.payload.myBooks;
      },
    );
  },
});

export const { clearMyBooksList } = myBooksSlice.actions;

export const myBooksReducer = myBooksSlice.reducer;
