import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchArtRating } from 'modules/comments/helpers/fetchArtRating';
import { GetServerSidePropsContext } from 'next';

import { externalServicesUrls } from 'constants/externalServicesUrls';

interface IGetBookRatingThunkProps {
  litresArtId: number;
  context?: GetServerSidePropsContext;
}

export const getBookRatingThunk = createAsyncThunk(
  'bookMeta/getBookRating',
  async ({ litresArtId, context }: IGetBookRatingThunkProps) => {
    return await fetchArtRating(
      externalServicesUrls.artInfo.production,
      externalServicesUrls.artInfo.master,
      litresArtId,
      context,
    );
  },
);
