import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import type { TNotificationsType } from 'sp-redux/slices/notifications/types';

import { doFetch } from 'utils/doFetch';

export interface INotificationReadThunkProps {
  id: string;
  type: TNotificationsType;
  context?: GetServerSidePropsContext;
}

export const patchNotificationReadThunk = createAsyncThunk(
  'notifications/id',
  async ({ id, context }: INotificationReadThunkProps) => {
    const apiPointName = 'notificationRead';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: {
        id,
      },
    });
  },
);
