import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { EUiModalTypes } from './types';
import type { IUIAppTypes } from './types';

const initialState: IUIAppTypes = {
  modalFields: {},
  isOpenModal: false,
  isOpenDrawer: false,
  modalType: EUiModalTypes.none,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openModal(
      state,
      action: PayloadAction<EUiModalTypes | Record<string, EUiModalTypes>>,
    ) {
      if (typeof action.payload === 'string') {
        state.modalType = action.payload;
      } else if (action.payload.modalType) {
        state.modalType = action.payload.modalType;
        state.modalFields = action.payload;
      } else {
        return state;
      }
      state.isOpenModal = true;
      state.isOpenDrawer = false;
    },
    closeModal(state) {
      state.isOpenModal = false;
      state.modalType = EUiModalTypes.none;
    },
    toggleDrawer(state) {
      state.isOpenDrawer = !state.isOpenDrawer;
    },
    closeDrawer(state) {
      state.isOpenDrawer = false;
    },
  },
});

export const { openModal, closeModal, toggleDrawer, closeDrawer } =
  uiSlice.actions;

export const uiReducer = uiSlice.reducer;
