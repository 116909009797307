import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getSeriesDetailsThunk = createAsyncThunk(
  'series/getSeriesDetails',
  async ({
    seriesId,
    context,
  }: {
    seriesId: string;
    context?: GetServerSidePropsContext;
  }) => {
    const apiPointName = 'getSeriesDetails';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { seriesId },
    });
  },
);
