import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetPodcastMetaThunkSignature {
  podcastId: string;
  context?: GetServerSidePropsContext;
}

export const getPodcastMetaByPodcastId = createAsyncThunk(
  'podcasts/getPodcastMetaByPodcastId',
  async ({ podcastId, context }: IGetPodcastMetaThunkSignature) => {
    const apiPointName = 'getPodcastMetaByPodcastId';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { podcastId },
    });
  },
);
