import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import type { IProfileStateType } from 'sp-redux/slices/profile/types';
import { getProfileThunk } from 'sp-redux/thunks/profile/getProfile';
import { getUserDirectAgreementThunk } from 'sp-redux/thunks/profile/getUserDirectAgreementThunk';

const initialState: IProfileStateType = {
  isFetched: false,
  isFetching: true,
  profile: {},
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.profile.first_name = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.profile.last_name = action.payload;
    },
    setMiddleName: (state, action: PayloadAction<string>) => {
      state.profile.middle_name = action.payload;
    },
    setBirthday: (state, action: PayloadAction<string>) => {
      state.profile.birthday = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(getProfileThunk.fulfilled, (state, action) => {
      if (action.payload) {
        const { result } = action.payload;

        state.isFetched = true;
        state.isFetching = false;
        state.profile = {
          ...result,
          // eslint-disable-next-line
          birthday: result.birthday ?? '',
          status: result.status,
        };
      }
    });

    builder.addCase(getUserDirectAgreementThunk.fulfilled, (state, action) => {
      state.profile.direct_agreement = action.payload.result;
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IProfileStateType>) => {
        return action.payload.profile;
      },
    );
  },
});

export const { setFirstName, setLastName, setMiddleName, setBirthday } =
  profileSlice.actions;

export const profileReducer = profileSlice.reducer;
