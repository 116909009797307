import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getAccountTaxDataByUserIdThunk } from 'sp-redux/thunks/account/getAccountTaxDataByUserId';

import { IAccountStateType } from './types';

const initialState: IAccountStateType = {
  isFetched: false,
  isFetching: true,
  accountTaxData: null,
};

const AccountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAccountTaxDataByUserIdThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(
      getAccountTaxDataByUserIdThunk.fulfilled,
      (state, action) => {
        state.isFetched = true;
        state.isFetching = false;
        // eslint-disable-next-line
        state.accountTaxData = action.payload.result || null;
      },
    );

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IAccountStateType>) => {
        return action.payload.account;
      },
    );
  },
});

export const accountReducer = AccountSlice.reducer;
