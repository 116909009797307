import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

interface ICancelCirculationOrderThunk {
  orderId: string;
  context?: GetServerSidePropsContext;
}

export const cancelCirculationOrderThunk = createAsyncThunk(
  'orders/orderCirculation/cancelCirculationOrder',
  async ({ orderId, context }: ICancelCirculationOrderThunk) => {
    const apiPointName = 'cancelCirculationOrder';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: { orderId },
      context,
    });
  },
);
