import type { IAPIResponse } from 'app.types';
import type { ISeriesDetails } from 'entities/Series/types';

import type { IEditSeriesAPIRequest } from 'sp-redux/slices/seriesDetails/types';

import { doFetch } from 'utils/doFetch';

export const editSeriesRequest = ({
  id,
  name,
  art_list,
}: IEditSeriesAPIRequest): Promise<IAPIResponse<ISeriesDetails>> => {
  const apiPointName = 'editSeries';
  return doFetch<typeof apiPointName>({
    apiPointName,
    urlParams: { seriesId: id },
    body: { id, art_list, name },
  });
};
