import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext, NextPageContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IServicesOrdersThunkProps {
  context?: GetServerSidePropsContext | NextPageContext;
  group_type?: string;
  status?: string;
}

export const getServicesOrdersThunk = createAsyncThunk(
  'orders/services',
  async ({ context, group_type, status }: IServicesOrdersThunkProps) => {
    const apiPointName = 'getServicesOrders';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      queryParams: {
        group_type,
        status,
      },
    });
  },
);
