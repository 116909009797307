import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext, NextPageContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface ICirculationOrdersThunkProps {
  context?: GetServerSidePropsContext | NextPageContext;
  group?: string;
}

export const getCirculationOrdersThunk = createAsyncThunk(
  'orders',
  async ({ context, group }: ICirculationOrdersThunkProps) => {
    const apiPointName = 'getCirculationOrders';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      queryParams: {
        group,
      },
    });
  },
);
