export enum EProfileStatuses {
  /**
   * Юзер не отсылал профиль на модерацию
   * Дефолтное значение
   */
  new = 'NEW',
  /**
   * Юзер отправит (сохранил) профиль на ревью модератора
   * Статус с опечаткой с бекенда (кек)
   */
  submitted = 'SUBMITTED',
  /**
   * Профиль был взят на модераию, модератор отметил его в очереди
   */
  unverified = 'UNVERIFIED',
  /**
   * Модератор поставил статус "требует доработки" и добавил коммент, описывающий эти доработки
   * a comment describing the revisions
   */
  rejected = 'REJECTED',
  /**
   * Модератор проверил профиль и заапрувил его
   */
  verified = 'VERIFIED',
}

export const DEFAULT_RESIDENT_COUNTRY = 'RU';
