import { createAsyncThunk } from '@reduxjs/toolkit';

import { fileToBase64 } from 'utils/fileToBase64';

async function uploadImage(file: File): Promise<{ image: string; file: File }> {
  const image = await fileToBase64(file);

  return {
    image,
    file,
  };
}

async function uploadImages(
  files: File[],
): Promise<{ image: string; file: File }[]> {
  return await Promise.all(
    files.map(async file => {
      const image = await fileToBase64(file);
      return { image, file };
    }),
  );
}

const uploadDocumentThunk = createAsyncThunk(
  'document/uploadDocumentScan',
  uploadImage,
);

const uploadDocumentsThunk = createAsyncThunk(
  'document/uploadDocumentsScan',
  uploadImages,
);

const uploadPhotoWithDocument = createAsyncThunk(
  'document/uploadPhotoWithDocument',
  uploadImage,
);

const uploadAdditionalScan = createAsyncThunk(
  'document/uploadAdditionalScan',
  uploadImage,
);

export {
  uploadDocumentThunk,
  uploadPhotoWithDocument,
  uploadAdditionalScan,
  uploadDocumentsThunk,
};
