import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getAllArtGenres } from 'sp-redux/thunks/genres/getAllArtGenres';

import type { IGenresStateType } from './types';

const initialState: IGenresStateType = {
  isFetched: false,
  isFetching: true,
  allGenres: [],
};

const GenresSlice = createSlice({
  name: 'genres',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllArtGenres.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getAllArtGenres.fulfilled, (state, action) => {
      state.isFetched = true;
      if (action.payload.result.length) {
        state.allGenres = action.payload.result;
      }
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IGenresStateType>) => {
        return action.payload.genres;
      },
    );
  },
});

export const genresReducer = GenresSlice.reducer;
