import { createAsyncThunk } from '@reduxjs/toolkit';

import { createQueryStringFilter } from 'components/complex/pages/moderation/utils/createQueryStringFilter';

import type { TAppState } from 'sp-redux';
import { limit } from 'sp-redux/slices/moderator/constants';

import { doFetch } from 'utils/doFetch';

export const getModerationProfilesThunk = createAsyncThunk(
  'moderation/getModerationProfiles',
  async ({ offset }: { [key: string]: number }, { getState }) => {
    const { moderation } = getState() as {
      moderation: TAppState['moderation'];
    };
    const {
      tabName,
      filtersCheckBoxGroup,
      filtersAutocompleteGroup: { mailModerators, mailProfiles },
    } = moderation;

    const apiPointName = tabName;

    const queryFormatComma = true;

    const autoCompleteParams: {
      [key: string]: string[];
    } = {
      moderator_email: mailModerators,
      profile_email: mailProfiles,
    };

    const queryParams = createQueryStringFilter(
      filtersCheckBoxGroup,
      autoCompleteParams,
    );

    queryParams['limit'] = limit;
    queryParams['offset'] = offset;

    const resultTestBooks = await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams,
      queryFormatComma,
    });

    return { ...resultTestBooks, apiPointName };
  },
);
