import type { IFilterGroupName } from 'sp-redux/slices/moderator/types';

export function createQueryStringFilter(
  filtersCheckBoxGroup: Record<string, IFilterGroupName>,
  autoCompleteParams: Record<string, string[] | string | number>,
): Record<string, string[] | string | number | boolean> {
  const queryParamsFilter: {
    [key: string]: string[];
  } = {};
  for (const groupName in filtersCheckBoxGroup) {
    const group = filtersCheckBoxGroup[groupName];

    queryParamsFilter[groupName] = [];

    for (const fieldName in group) {
      if (group[fieldName].checked) {
        queryParamsFilter[groupName].push(fieldName);
      }
    }
  }

  return Object.assign(autoCompleteParams, queryParamsFilter);
}
