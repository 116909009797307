import classNames from 'classnames';
import type { Id, ToastContent, ToastOptions } from 'react-toastify';
import { toast as reactToastifyToast } from 'react-toastify';

import type { IToastOptions } from './types';

import s from './Toast.module.scss';

const reconfigureOptions = (
  options?: IToastOptions,
): ToastOptions | undefined => {
  if (!options) {
    return;
  }

  const { wideness, className, ...rest } = options;
  return {
    ...rest,
    className: classNames(className, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Toastify__toast-wide': wideness === 'wide',
    }),
  };
};

export const toast = (
  content: ToastContent,
  options?: IToastOptions,
): string | number => {
  return reactToastifyToast(content, reconfigureOptions(options));
};

toast.error = (content: ToastContent, options?: IToastOptions): Id =>
  reactToastifyToast.error(
    content,
    reconfigureOptions({
      autoClose: false,
      closeOnClick: false,
      className: s.errorToast,
      ...options,
    }),
  );

toast.info = (content: ToastContent, options?: IToastOptions): Id =>
  reactToastifyToast.info(content, reconfigureOptions(options));

toast.warning = (content: ToastContent, options?: IToastOptions): Id =>
  reactToastifyToast.warning(content, reconfigureOptions(options));

toast.success = (content: ToastContent, options?: IToastOptions): Id =>
  reactToastifyToast.success(content, reconfigureOptions(options));

toast.dismiss = reactToastifyToast.dismiss;
toast.onChange = reactToastifyToast.onChange;
