import { createAsyncThunk } from '@reduxjs/toolkit';
import type { EBooksTypes } from 'modules/statistics/pages/types';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IReadingStatisticsThunkProps {
  art_type: EBooksTypes;
  from_date: number;
  to_date: number;
  offset: number;
  art_ids?: number[];
  user_id?: string | string[];
  context?: GetServerSidePropsContext;
}

export const getReadingStatisticsThunk = createAsyncThunk(
  'statistics/getReadingStatistics',
  async ({
    art_type,
    from_date,
    to_date,
    offset,
    art_ids,
    user_id,
    context,
  }: IReadingStatisticsThunkProps) => {
    const apiPointName = 'getReadingStatistics';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      body: {
        art_type,
        from_date,
        to_date,
        limit: 10,
        offset,
      },
      queryParams: { art_ids, user_id },
      context,
    });
  },
);
