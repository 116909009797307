import { createAsyncThunk } from '@reduxjs/toolkit';

import type { IApiPointsFetchParamsType } from 'constants/apiPoints';

import { doFetch } from 'utils/doFetch';

export const checkRoyaltyOtcThunk = createAsyncThunk(
  'userInfo/checkRoyaltyOtc',
  async ({ otc }: IApiPointsFetchParamsType['checkRoyaltyOtc']['request']) => {
    const apiPointName = 'checkRoyaltyOtc';
    return await doFetch<typeof apiPointName>({
      apiPointName,
      body: {
        otc,
      },
    });
  },
);
