import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { EDiscountsStatuses } from 'sp-redux/slices/bookMeta/types';

import { doFetch } from 'utils/doFetch';

export interface IGetBookDiscountsThunkProps {
  bookId: string;
  art_type: string;
  limit?: number;
  offset?: number;
  context?: GetServerSidePropsContext;
}

export const getBookCreatedDiscountsThunk = createAsyncThunk(
  'bookMeta/getBookCreatedDiscounts',
  async ({
    bookId,
    art_type,
    limit = 10,
    offset,
    context,
  }: IGetBookDiscountsThunkProps) => {
    const apiPointName = 'getBookDiscountsList';

    const discount_type = EDiscountsStatuses.created;
    const version = 'v2';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: { bookId },
      queryParams: { limit, offset, art_type, discount_type, version },
      context,
    });
  },
);
