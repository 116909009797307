import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchArtReviews } from 'modules/comments/helpers/fetchArtReviews';
import { GetServerSidePropsContext } from 'next';

import { externalServicesUrls } from 'constants/externalServicesUrls';

const requestLimit = 1;

interface IGetReviewByIdThunkProps {
  litresArtId: number;
  reviewId?: string;
  commentId?: string;
  after?: string;
  context?: GetServerSidePropsContext;
}

export const getReviewByIdThunk = createAsyncThunk(
  'bookMeta/getReviewByIdThunk',
  async ({
    litresArtId,
    reviewId,
    commentId,
    after,
    context,
  }: IGetReviewByIdThunkProps) => {
    return await fetchArtReviews({
      prodUrl: externalServicesUrls.artComments.production(litresArtId),
      stageUrl: externalServicesUrls.artComments.master(litresArtId),
      o: 'new',
      limit: requestLimit,
      after,
      with_review_id: reviewId,
      with_reply_id: commentId,
      context,
    });
  },
);
