import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getProfileModerationHistoryThunk = createAsyncThunk(
  'verificationProfile/getProfileModerationHistory',
  async ({
    profileId,
    context,
  }: {
    profileId: string;
    context?: GetServerSidePropsContext;
  }) => {
    const apiPointName = 'getProfileModerationHistory';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: { profileId },
    });
  },
);
