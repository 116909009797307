import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getAllArtGenres = createAsyncThunk(
  'bookMeta/getAllArtGenres',
  async (context?: GetServerSidePropsContext) => {
    const apiPointName = 'getGenresList';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
    });
  },
);
